import Event from "@/orm/models/Event"
import Lifeguard from "@/orm/models/Lifeguard"
import LifeguardRole from "@/orm/models/LifeguardRole"
import { Model } from 'pinia-orm'
import { BelongsTo, Num, Str, Uid } from 'pinia-orm/decorators'

export default class EventLifeguard extends Model {
    static entity = 'events-lifeguards'

    @Uid() declare id: string
    @Uid() declare eventId: string
    @BelongsTo( () => Event, 'eventId' ) declare event: Event
    @Str( '', { notNullable: true } ) declare lifeguardId: string
    @BelongsTo( () => Lifeguard, 'lifeguardId' ) declare lifeguard: Lifeguard
    @Num( 0, { notNullable: true } ) declare lifeguardRoleId: number
    @BelongsTo( () => LifeguardRole, 'lifeguardRoleId' ) declare lifeguardRole: LifeguardRole

    get dump () {
        return {
            lifeguardId: this.lifeguardId,
            lifeguardRoleId: this.lifeguardRoleId
        }
    }

    static piniaOptions = {
        persist: true
    }
}
