import axios from 'axios'

const httpClient = axios.create( {
	baseURL: 'https://api.sailing.cz',
	timeout: 15000,
	headers: {
		"Content-Type": "application/json; charset=utf-8",
	}
} )

export function apiRequest ( authToken: string = '' ) {
	if ( authToken.length > 0 )
		httpClient.defaults.headers.common[ "Authorization" ] = "Bearer " + authToken
	else
		httpClient.defaults.headers.common[ "Authorization" ] = ""

	return httpClient
}
export default apiRequest