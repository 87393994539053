<template>
    <q-form ref="innerForm" v-bind="$attrs">
        <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope || {}" />
        </template>
    </q-form>
</template>

<script setup lang="ts">
import { provide, ref, watch } from "vue"

const selectors = 'input:enabled,textarea:enabled,select:enabled,button:not(.qe-ignore-focus)'

const innerForm = ref()

const validate = () => {
    return innerForm.value.validate()
}

defineExpose( { validate } )

const focusNextCounter = ref( 0 )
const focusResetCounter = ref( 0 )

provide( 'focusNextCounter', focusNextCounter )
provide( 'focusResetCounter', focusResetCounter )

watch( focusNextCounter, () => {
    const inputs = Array.from( innerForm.value.$el.querySelectorAll( selectors ) )

    if ( inputs.length == 0 )
        return

    let current = inputs.indexOf( document.activeElement )

    if ( ++current >= inputs.length )
        current = 0

    inputs[ current ].focus()
} )

watch( focusResetCounter, () => {
    const inputs = Array.from( innerForm.value.$el.querySelectorAll( selectors ) )

    if ( inputs.length == 0 )
        return

    inputs[ 0 ].focus()
} )
</script>