import { Plugin } from 'vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createPinia } from 'pinia'
import { createORM } from 'pinia-orm'
import { createPiniaOrmAxios } from '@pinia-orm/axios'
import axios from 'axios'

export const datalayer: Plugin = ( app ) => {
    // implicit data layer manager
    const pinia = createPinia()

    pinia.use( piniaPluginPersistedstate )

    pinia.use( createORM( {
        plugins: [
            createPiniaOrmAxios( {
                axios,
                baseURL: 'https://api.sailing.cz',
                timeout: 60000,
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                }
            } )
        ]
    } ) )

    // apply data layer to passed application
    app.use( pinia )
}
