import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { AxiosRepository } from "@pinia-orm/axios"
import EventCoefficient from '@/orm/models/EventCoefficient'
import { useRepo } from "pinia-orm"

export default class EventCoefficientsRepo extends AxiosRepository<EventCoefficient> {
    use = EventCoefficient

    eventCoefficientsList () {
        const allCoef = this.all().map( coef => ( {
            value: coef.id, label: coef.id + ' ' + coef.name, weight: coef.value, common: coef.common, lastCommon: false
        } ) )

        const list = allCoef.sort( ( a, b ) => ( a.common == b.common ? ( a.weight - b.weight == 0 ? a.label.localeCompare( b.label ) : a.weight - b.weight ) : ( b.common - a.common ) ) )

        list[ list.findLastIndex( coef => coef.common ) ].lastCommon = true

        return list
    }

    update () {
        const authToken = useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken )

        return this.api().get( '/catalogs/eventCoefficients', {
            headers: { Authorization: authToken.length > 0 ? "Bearer " + authToken : "" },
            dataTransformer: ( { data } ) => {
                return data.map( ( line ) => ( {
                    id: String( line.value ) + ( Object.hasOwn( line, 'shortcut' ) && Object.hasOwn( line.shortcut, 'cs' ) ? String( line.shortcut.cs ) : '' ),
                    name: String( line.name.cs ),
                    value: parseInt( line.value ),
                    shortcut: Object.hasOwn( line, 'shortcut' ) && Object.hasOwn( line.shortcut, 'cs' ) ? String( line.shortcut.cs ) : undefined,
                    note: Object.hasOwn( line, 'note' ) ? String( line.note ) : undefined,
                    raceMin: Object.hasOwn( line, 'raceMin' ) ? parseInt( line.raceMin ) : undefined,
                    raceMax: Object.hasOwn( line, 'raceMax' ) ? parseInt( line.raceMax ) : undefined,
                    weight: Object.hasOwn( line, 'weight' ) ? parseInt( line.weight ) : undefined
                } ) )
            }
        } )
    }
}