<template>
    <q-tab-panel name="EventManagementTab">
        <div class="q-mb-md">
            {{ ts( 'saveToFileHint' ) }}
        </div>

        <q-form autofocus>
            <q-btn
                :label="ts( 'saveToFile' )" outline color="secondary"
                @click="saveToFile()" />
        </q-form>

        <div class="q-mt-xl q-mb-md">
            {{ ts( 'removeEventHint' ) }}
        </div>

        <q-form>
            <q-btn
                :label="ts( 'removeEvent' )" outline color="negative"
                @click="removeEvent()" />
        </q-form>
    </q-tab-panel>
</template>

<script setup lang="ts">
import Confirmation from "@/components/dialogs/Confirmation.vue"
import EventsRepo from "@/orm/repositories/EventsRepo"
import { DateFormat, ds, ts } from '@/plugins/i18n-formatted'
import { Options } from '@model/Setting'
import SettingsRepo from '@repo/SettingsRepo'
import { useRepo } from "pinia-orm"
import { useQuasar } from "quasar"
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { saveAs } from 'file-saver'
import { version } from '@/../package.json'

const quasar = useQuasar()
const router = useRouter()
const route = useRoute()

const event = ref( useRepo( EventsRepo ).getEvent( String( route.params.eventId ) ) )

useRepo( EventsRepo ).selectEvent( event.value.id )

function removeEvent () {
    quasar.dialog( {
        component: Confirmation,
        componentProps: {
            icon: 'delete',
            question: ts( 'reallyRemoveEvent', { eventTitle: event.value.fullTitle } )
        }
    } ).onOk( () => {
        useRepo( EventsRepo ).destroy( event.value.id )

        router.push( { name: 'MainPage' } )
    } )
}

function saveToFile () {
    const settingsRepo = useRepo( SettingsRepo )

    const data = {
        software: 'Score System',
        version: version,
        contentPurpose: 'event saved as a file',
        lang: settingsRepo.getOption( Options.Language ),
        dbStructureVersion: settingsRepo.getOption( Options.DbStructureVersion ),
        sailingDataTimestamp: ds( settingsRepo.getOption( Options.LastSailingDataUpdated ), DateFormat.System ),
        authorizedScorer: settingsRepo.getOption( Options.AuthFullName ),
        generated: ds( new Date, DateFormat.System ),
        event: event.value.dump
    }

    const blob = new Blob( [ JSON.stringify( data ) ], { type: "application/json; charset=utf-8" } )

    saveAs( blob, 'event-' + event.value.ctlId + '.json' )
}
</script>
