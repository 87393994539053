import { Model } from 'pinia-orm'
import { Num, Str } from 'pinia-orm/decorators'

export default class LifeguardRole extends Model {
    static entity = 'lifeguard-roles'

    static readonly MainLifeguard = 7
    static readonly NoLifeguards = 9

    @Num( 0, { notNullable: true } ) declare id: number
    @Str( '', { notNullable: true } ) declare role: string
    @Str( null ) declare shortcut: string
    @Num( 0 ) declare points: number

    get isMainLifeguard () {
        return this.id === LifeguardRole.MainLifeguard
    }

    static piniaOptions = {
        persist: true
    }
}
