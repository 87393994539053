import parsePhoneNumber from 'libphonenumber-js'

String.prototype.toAscii = function () {
    return String( this ).normalize( "NFD" ).replace( /\p{Diacritic}/gu, "" )
}

String.prototype.toSearchable = function () {
    return String( this ).toAscii().toLowerCase()
}

String.prototype.isNumeric = function () {
    return ( typeof ( this ) === 'number' || typeof ( this ) === "string" && this.trim() !== '' ) && !isNaN( this as number )
}

String.prototype.shorten = function ( max: number ): string {
    return this.length > max ? this.substring( 0, max - 2 ) + "..." : this
}

String.prototype.toLowerCaseFirst = function () {
    return this.substring( 0, 1 ).toLowerCase() + this.substring( 1 )
}

String.prototype.toUpperCaseFirst = function () {
    return this.substring( 0, 1 ).toUpperCase() + this.substring( 1 )
}

String.prototype.formatPhone = function () {
    const phone = String( this ).trim()

    if ( phone === '' )
        return ''

    const phoneNumber = parsePhoneNumber( phone, 'CZ' )

    if ( phoneNumber === undefined )
        return phone

    return '<a href="' + phoneNumber.getURI() + '">' + phoneNumber?.formatInternational() + '</a>'
}

String.prototype.formatEmail = function () {
    const email = String( this ).trim()

    if ( email === '' )
        return ''

    return '<a href="mailto:' + email + '">' + email + '</a>'
}
