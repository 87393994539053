import RefereeTitle from "@/orm/models/RefereeTitle"
import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { AxiosRepository } from "@pinia-orm/axios"
import { useRepo } from "pinia-orm"

export default class RefereeTitlesRepo extends AxiosRepository<RefereeTitle> {
    use = RefereeTitle

    hasRefereeTitles () {
        return this.all().length > 0
    }

    update () {
        const authToken = useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken )

        return this.api().get( '/catalogs/refereeTitles', {
            headers: { Authorization: authToken.length > 0 ? "Bearer " + authToken : "" },
            dataTransformer: ( { data } ) => {
                return data.map( ( line ) => ( {
                    id: parseInt( line.id ),
                    title: String( line.title.cs ),
                    shortcut: ( Object.hasOwn( line, 'shortcut' ) && Object.hasOwn( line.shortcut, 'cs' ) ) ? String( line.shortcut.cs ) : undefined
                } ) )
            }
        } )
    }
}