<template>
    <common-page :label="event?.fullTitle" :use-custom-content="true" page-class="event-width-dialog-standard">
        <q-tabs v-model="tab" align="justify" dense active-color="primary">
            <q-route-tab name="EventConfigurationTab" :to="{ name: 'EventConfigurationTab' }" icon="event" :label="ts( 'eventInfoShortcut' )" />
            <q-route-tab name="EventStartListTab" :to="{ name: 'EventStartListTab' }" icon="list" :label="ts( 'crewListShortcut' )" />
            <q-route-tab name="EventNoticeboardTab" :to="{ name: 'EventNoticeboardTab' }" icon="info" :label="ts( 'noticeboard' )" />
            <q-route-tab
                v-for="race in event?.races" :key="race.id"
                :name="'EventRaceTab-' + race.race" :to="{ name: 'EventRaceTab', params: { raceId: race.race } }" icon="flag" :label="ts( 'raceNum', { num: race.race } )" />
            <q-route-tab name="EventRaceTab" :to="{ name: 'EventRaceTab' }" icon="add" :label="ts( event?.races.length > 3 ? 'newRaceShortcut' : 'newRace' )" />
            <q-route-tab name="EventResultsTab" :to="{ name: 'EventResultsTab' }" icon="check" :label="ts( 'resultsShortcut' )" />
            <q-route-tab name="EventManagementTab" :to="{ name: 'EventManagementTab' }" icon="settings" :label="ts( 'manageEventShortcut' )" />
        </q-tabs>

        <q-separator />

        <router-view
            v-slot="{ Component }"
            @log-in-and-update="emits( 'logInAndUpdate' )">
            <keep-alive>
                <div class="minh">
                    <component :is="Component" />
                </div>
            </keep-alive>
        </router-view>
    </common-page>
</template>

<script setup lang="ts">
// Purpose: solve handling one selected event (dashboard and all event navigation)

import CommonPage from "@/components/parts/commonPage.vue"
import EventsRepo from "@/orm/repositories/EventsRepo"
import { ts } from "@/plugins/i18n-formatted"
import { useRepo } from "pinia-orm"
import { computed, ref, watch } from "vue"
import { useRoute } from "vue-router"

const route = useRoute()

const emits = defineEmits( [ 'logInAndUpdate' ] )

const event = computed( () => ( useRepo( EventsRepo ).getEvent( String( route.params.eventId ), true ) ) )

useRepo( EventsRepo ).selectEvent( event.value.id )

const tab = ref( event.value.currentTab )

watch(  tab, ( tab ) => {
    event.value.currentTab = tab
} )
</script>

<style lang="sass" scoped>
.minh
  min-height: 300px
</style>

<style lang="sass">
.event-width-dialog-standard
  min-width: 1024px !important
</style>