import { Model } from 'pinia-orm'
import { Num, Str } from 'pinia-orm/decorators'

export default class RefereeTitle extends Model {
    static entity = 'referee-titles'

    @Num( 0, { notNullable: true } ) declare id: number
    @Str( '', { notNullable: true } ) declare title: string
    @Str( null ) declare shortcut: string

    get visibleShortcut () {
        if ( this.shortcut !== '' )
            return this.shortcut
        else {
            return this.title.split( /\s/ ).reduce( ( response, word ) => response += word.slice( 0, 1 ), '' )
        }
    }

    static piniaOptions = {
        persist: true
    }
}