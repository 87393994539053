import Referee from "@/orm/models/Referee"
import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { AxiosRepository } from "@pinia-orm/axios"
import { useRepo } from "pinia-orm"

export default class RefereesRepo extends AxiosRepository<Referee> {
    use = Referee

    hasReferees () {
        return this.all().length > 0
    }

    orderedList ( event = null ) {
        let list = this.all()

        if ( event != null ) {
            const referees = event.referees.map( ( eventReferee ) => ( eventReferee.referee.id ) )

            list = list.filter( ( referee ) => ( referees.indexOf( referee.id ) == -1 ) )
        }

        return list.map( ( referee ) => ( {
                label: referee.reverseFullNameWithIdentification,
                sortLabel: referee.reverseFullName + ' ' + referee.id,
                searchLabel: referee.reverseFullName + ' ' + referee.fullName + ' R' + referee.id,
                value: referee.id
            } ) )
            .sort( ( a, b ) => a.sortLabel.localeCompare( b.sortLabel ) )
    }

    update () {
        const authToken = useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken )

        if ( authToken.length === 0 ) {
            this.flush()

            return Promise.allSettled( [] )
        }

        return this.api().get( '/catalogs/referees', {
            headers: { Authorization: "Bearer " + authToken },
            dataTransformer: ( { data } ) => {
                return data.map( ( line ) => ( {
                    id: String( line.id ),
                    firstName: String( line.firstName ),
                    lastName: String( line.lastName ),
                    email: Object.hasOwn( line, 'email' ) ? String( line.email ) : undefined,
                    phone: Object.hasOwn( line, 'phone' ) ? String( line.phone ) : undefined,
                    regId: String( line.memberId ).substring( 0, 4 ) + "-" + String( line.memberId ).substring( 4 ),
                    refTitleIds: line.titles.map( ( title ) => ( parseInt( title ) ) )
                } ) )
            }
        } )
    }
}
