<template>
    <q-tab-panel name="EventResultsTab">
        <q-tabs v-model="selClass" align="left" dense no-caps narrow-indicator active-color="secondary">
            <q-tab :ripple="false" :name="boatClass.boatClass.id" :label="boatClass.boatClass.name"
                   v-for="boatClass in attendedClasses"
                   :key="boatClass.id" />
        </q-tabs>

        <q-markup-table flat class="q-mt-lg">
            <thead>
            <tr>
                <th class="text-right">{{ ts( 'orderShortcut' ) }}</th>
                <th class="text-left">{{ ts( 'sailNo' ) }}</th>
                <th class="text-left" v-if="useBoatNames">{{ ts( 'boatName' ) }}</th>
                <th class="text-left">{{ ts( 'crew' ) }}</th>
                <th class="text-right" v-for="( race, index ) in availableRaces" :key="race.id">R{{ index + 1 }}</th>
                <th class="text-right">{{ ts( 'total' ) }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="results.length === 0 || availableRaces.length === 0">
                <td colspan="6" class="text-center">
                    <div class="q-mt-md">
                        <q-icon name="warning" color="negative" size="40px" />
                        <span class="q-ml-md">{{ ts( 'noEnteredResults' ) }}</span>
                    </div>
                </td>
            </tr>
            <tr v-for="( boat, index ) in results" :key="boat.id" v-else>
                <td class="text-right">{{ index + 1 }}.</td>
                <td>{{ boat.boat.sailNo }}</td>
                <td v-if="useBoatNames">{{ boat.boat.name }}</td>
                <td class="minicrewlist">
                    <div v-for="crew in boat.boat.orderedCrewList" :key="crew.id" :class="crew.captain ? 'captain' : ''">
                        {{ crew.reverseFullName }}
                    </div>
                </td>
                <td class="text-right" v-for="race in boat.results" :key="race.race"
                    :class="( race.isDiscarded ? 'discarded' : '' ) + ( race.decided ? '' : ' auto_result' )" :title="tcs( 'pointsNo', ns( race.points, 'points' ) )">
                    {{ race.result }}{{ race.isDiscarded ? '*' : '' }}
                </td>
                <td class="text-right text-weight-bold">{{ ns( boat.points, 'points' ) }}</td>
            </tr>
            </tbody>
        </q-markup-table>

        <div class="text-right q-mt-lg" v-if="results.length > 0 && availableRaces.length > 0">
            <q-btn :label="ts( 'printSemiResults' )" outline color="secondary" @click="printResults( false )" />
            <q-btn :label="ts( 'printResults' )" outline color="secondary" class="q-ml-md" @click="printResults( true )" />
        </div>
    </q-tab-panel>
</template>

<script setup lang="ts">
import { otherResults } from "@/orm/catalogs/OtherResults"
import { DateFormat, ds, ts, tcs, ns } from '@/plugins/i18n-formatted'
import nvl from "@/utils/nvl"
import EventsRepo from '@repo/EventsRepo'
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import { useRepo } from 'pinia-orm'
import { QMarkupTable, QTab, QTabPanel, QTabs } from 'quasar'
import { sprintf } from "sprintf-js"
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

pdfMake.addVirtualFileSystem( pdfFonts )

const route = useRoute()

const event = computed( () => ( useRepo( EventsRepo ).getEvent( String( route.params.eventId ), true ) ) )

useRepo( EventsRepo ).selectEvent( event.value.id )

const attendedClasses = computed( () => ( event.value.orderedClasses ) )

const selClass = ref( attendedClasses.value[ 0 ].boatClass.id )

const results = computed( () => ( event.value.results( selClass.value ) ) )

const availableRaces = computed( () => ( event.value.availableRaces( selClass.value ) ) )

const useBoatNames = computed( () => ( event.value.getClass( selClass.value )?.boatClass.useBoatNames ) )

function printResults ( final = false ) {
    const eventClass = event.value.getClass( selClass.value )
    const legend = otherResults()
    const legendLength = Math.floor( legend.length / 4 )
    const inLineResults = eventClass.boatClass.useBoatNames ? 4 : 6

    let counter = 0

    const genHeader = availableRaces.value.map( ( race, index ) => ( { text: ( index + 1 ) + ".", style: "em_race_result" } ) )

    const innerHeader = Array.range( 0, Math.ceil( genHeader.length / inLineResults ) ).map( i => genHeader.slice( i * inLineResults, ( i + 1 ) * inLineResults ) )

    if ( innerHeader.length > 1 )
        while ( innerHeader[ innerHeader.length - 1 ].length < inLineResults )
            innerHeader[ innerHeader.length - 1 ].push( '' )

    const now = ds( new Date(), DateFormat.DateTimeCommon )

    const content = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [ 20, 40, 20, 20 ],
        header: ( currentPage ) => ( [
            {
                marginLeft: 20,
                marginRight: 20,
                marginTop: 15,
                table: {
                    headerRows: 0,
                    widths: [ '*', 'auto' ],
                    body: [
                        [
                            { text: ts( final ? "final-results" : "intermediate-results", { timestamp: now } ), style: "h1" },
                            { marginTop: 6, text: ts( "pageNo", { pageNo: currentPage } ), alignment: 'right' }
                        ]
                    ]
                },
                layout: {
                    vLineWidth: () => ( 0 ),
                    hLineWidth: ( i, node ) => ( i === node.table.body.length ),
                    hLineColor: 'black'
                }
            }
        ] ),
        content: [
            {
                marginTop: 8,
                table: {
                    headerRows: 0,
                    widths: [ '*', 'auto' ],
                    body: [
                        [
                            {
                                text: [
                                    { text: ts( "event" ) + ":  ", style: "em" },
                                    { text: event.value.title?.shorten( 50 ), style: "common" }
                                ]
                            },
                            {
                                text: [
                                    { text: ts( "eventStart" ) + "  ", style: "em" },
                                    { text: ds( new Date( event.value.from ), DateFormat.EventTo ), style: "common" },
                                    { text: "  " + ts( "eventUntil" ) + "  ", style: "em" },
                                    { text: ds( new Date( event.value.to ), DateFormat.EventTo ), style: "common" }
                                ]
                            }
                        ],
                        [
                            {
                                text: [
                                    { text: ts( "ctlBare" ) + ":  ", style: "em" },
                                    { text: event.value.ctlId, style: "common" }
                                ]
                            },
                            {
                                text: [
                                    { text: ts( "mainReferee" ) + ":  ", style: "em" },
                                    { text: event.value.mainReferee?.reverseFullNameWithId, style: "common" }
                                ]
                            }
                        ],
                        [
                            {
                                text: [
                                    { text: ts( "eventOrganizer" ) + ":  ", style: "em" },
                                    { text: event.value.clubTitleShort?.shorten( 50 ), style: "common" }
                                ]
                            },
                            {
                                text: [
                                    { text: ts( "venue" ) + ":  ", style: "em" },
                                    { text: event.value.venueTitle?.shorten( 50 ), style: "common" }
                                ]
                            }
                        ],
                        [
                            {
                                text: [
                                    { text: ts( "director" ) + ":  ", style: "em" },
                                    { text: event.value.director?.shorten( 50 ), style: "common" }
                                ]
                            },
                            {
                                text: [
                                    { text: ts( "sponsor" ) + ":  ", style: "em" },
                                    { text: event.value.sponsor?.shorten( 50 ), style: "common" }
                                ]
                            }
                        ]
                    ]
                },
                layout: {
                    vLineWidth: () => ( 0 ),
                    hLineWidth: () => ( 0 )
                }
            },
            {
                marginTop: 8,
                marginBottom: 8,
                table: {
                    headerRows: 0,
                    widths: [ '*', 'auto', 'auto', 'auto' ],
                    body: [
                        [
                            {
                                marginTop: 4,
                                marginBottom: 4,
                                marginLeft: 4,
                                text: [
                                    { text: ts( "boatClass" ) + ":  ", style: "em" },
                                    { text: eventClass.boatClass.shortcut + " " + eventClass.boatClass.name, style: "em" }
                                ]
                            },
                            {
                                marginTop: 4,
                                marginBottom: 4,
                                marginLeft: 8,
                                marginRight: 8,
                                alignment: 'right',
                                text: [
                                    { text: ts( "noOfBoats" ) + ":  ", style: "em" },
                                    { text: results.value.length, style: "em" }
                                ]
                            },
                            {
                                marginTop: 4,
                                marginBottom: 4,
                                marginLeft: 8,
                                marginRight: 8,
                                alignment: 'right',
                                text: [
                                    { text: ts( "plannedCoefficient" ) + ":  ", style: "em" },
                                    { text: eventClass.weight, style: "em" }
                                ]
                            },
                            {
                                marginTop: 4,
                                marginBottom: 4,
                                marginRight: 4,
                                alignment: 'right',
                                text: [
                                    { text: ts( "coefficient" ) + ":  ", style: "em" },
                                    { text: eventClass.calculatedWeight, style: "em" }
                                ]
                            }
                        ]
                    ]
                },
                layout: {
                    vLineWidth: ( i, node ) => ( ( i === 0 || i === node.table.widths.length ) ? 1 : 0 ),
                    hLineWidth: ( i, node ) => ( ( i === 0 || i === node.table.body.length ) ? 1 : 0 ),
                    hLineColor: 'black',
                    vLineColor: 'black'
                }
            },
            {
                table: {
                    headerRows: Math.ceil( availableRaces.value.length / inLineResults ),
                    widths: [ 'auto', '*', 'auto', ...( eventClass.boatClass.useBoatNames ? [ 'auto' ] : [] ), 'auto', 'auto', '*', ...innerHeader[ 0 ].map( () => 25 ), 'auto' ],
                    body: [
                        ...innerHeader.map( ( inner, index ) => (
                            [
                                ...( index === innerHeader.length - 1 ? [
                                    { text: ts( "orderShortcut" ).toLowerCase(), style: "em" },
                                    { text: ts( "sail" ).toLowerCase(), style: "em" },
                                    ...( eventClass.boatClass.useBoatNames ? [ { text: ts( "boat" ).toLowerCase(), style: "em" } ] : [] ),
                                    { text: ts( "crew" ).toLowerCase(), style: "em" },
                                    { text: ts( "regId" ).toLowerCase(), style: "em" },
                                    { text: ts( "categoryShortcut" ).toLowerCase(), style: "em" },
                                    { text: ts( "club" ).toLowerCaseFirst(), style: "em" },
                                ] : [ '', '', ...( eventClass.boatClass.useBoatNames ? [ '' ] : [] ), '', '', '', '' ] ),
                                ...inner,
                                ...( index === innerHeader.length - 1 ? [
                                    { text: ts( "points" ).toLowerCase(), style: "em", alignment: "right" }
                                ] : [ '' ] )
                            ]
                        ) ),
                        ...( results.value.map( function ( boat ) {
                                const res = boat.results.map( ( race ) => ( { text: race.result + ( race.isDiscarded ? '*' : '' ), style: race.decided ? '' : 'auto_result' } ) )

                                let template = [
                                    { text: sprintf( "%d.", ++counter ), alignment: "right" },
                                    boat.boat.sailNo,
                                    ...( eventClass.boatClass.useBoatNames ? [ nvl( boat.boat.name, '' ).shorten( 20 ) ] : [] ),
                                    '',
                                    '',
                                    '',
                                    '',
                                    ...res.slice( 0, innerHeader[ 0 ].length ),
                                    { text: ns( boat.points, 'points' ), alignment: "right", style: "em" }
                                ]

                                if ( boat.boat.crew === undefined || boat.boat.crew === null || boat.boat.crew.length === 0 ) {
                                    return innerHeader.map( ( ign, index ) => {
                                        let line = template

                                        const rowRes = res.slice( ( index + 1 ) * inLineResults, ( index + 2 ) * inLineResults )

                                        while ( rowRes.length < inLineResults )
                                            rowRes.push( '' )

                                        template = [ '', '', ...( eventClass.boatClass.useBoatNames ? [ '' ] : [] ), '', '', '', '', ...rowRes, '' ]

                                        return line
                                    } )
                                }

                                let crewRes = boat.boat.crew.map( function ( crew, index ) {
                                    let line = template

                                    const rowRes = res.slice( ( index + 1 ) * inLineResults, ( index + 2 ) * inLineResults )

                                    while ( rowRes.length < innerHeader[ 0 ].length )
                                        rowRes.push( '' )

                                    template = [ '', '', ...( eventClass.boatClass.useBoatNames ? [ '' ] : [] ), '', '', '', '', ...rowRes, '' ]

                                    line[ eventClass.boatClass.useBoatNames ? 3 : 2 ] = crew.reverseFullName.shorten( 25 )
                                    line[ eventClass.boatClass.useBoatNames ? 4 : 3 ] = crew.regId
                                    line[ eventClass.boatClass.useBoatNames ? 6 : 5 ] = crew.visibleClub.shorten( 20 )

                                    return line
                                } )

                                const solved = boat.boat.crew.length

                                crewRes = [ ...crewRes, ...Array.range( solved + 1, innerHeader.length + 1 ).map( ( ign, index ) => {
                                    let line = template

                                    const rowRes = res.slice( ( index + solved + 1 ) * inLineResults, ( index + solved + 2 ) * inLineResults )

                                    while ( rowRes.length < innerHeader[ 0 ].length )
                                        rowRes.push( '' )

                                    template = [ '', '', ...( eventClass.boatClass.useBoatNames ? [ '' ] : [] ), '', '', '', '', ...rowRes, '' ]

                                    return line
                                } ) ]

                                return crewRes
                            }
                        ).flat( 1 ) )
                    ]
                },
                layout: {
                    vLineWidth: () => ( 0 ),
                    hLineWidth: ( i, node ) => ( ( i === node.table.headerRows || i === node.table.body.length ) ? 1 : 0 ),
                    vLineColor: 'black'
                },
                style: 'results'
            },
            {
                marginTop: 12,
                columnGap: 16,
                columns: [
                    ...Array.range( 0, 4 ).map( i => ( {
                        width: '*',
                        stack: legend.slice( i * legendLength, i === 3 ? legend.length + 1 : ( i + 1 ) * legendLength ).map( result => result.value + ' – ' + result.short )
                    } ) )
                ],
                style: 'legend'
            },
            {
                marginTop: 8,
                text: ts( final ? 'finalResultsInfo' : 'tempResultsInfo' ),
                style: 'legend'
            }
        ],
        defaultStyle: {
            font: "Roboto",
            fontSize: 10,
            opacity: .6
        },
        styles: {
            h1: {
                fontSize: 16,
                bold: true,
                opacity: 1
            },
            common: {},
            em: {
                bold: true,
                opacity: 1
            },
            em_race_result: {
                bold: true,
                opacity: 1,
                fontSize: 8
            },
            results: {
                fontSize: 9
            },
            legend: {
                fontSize: 8
            },
            auto_result: {
                italics: true
            }
        }
    }

    pdfMake.createPdf( content ).download( "ctl" + event.value.ctlId + "-" + ( final ? 'results' : 'intermediate-results' ) + '-' + eventClass.boatClass.shortcut.toLowerCase() + '.pdf' )
}
</script>

<style scoped lang="sass">
.minicrewlist
  font-size: 80% !important

.auto_result
  font-style: italic

.captain
  font-weight: bold
</style>