import { Defaults, Options } from "@model/Setting"
import BoatClassesRepo from "@repo/BoatClassesRepo"
import ClubsRepo from "@repo/ClubsRepo"
import CountriesRepo from "@repo/CountriesRepo"
import CtlRepo from "@repo/CtlRepo"
import EventCoefficientsRepo from "@repo/EventCoefficientsRepo"
import LifeguardRolesRepo from "@repo/LifeguardRolesRepo"
import LifeguardsRepo from "@repo/LifeguardsRepo"
import MembersRepo from "@repo/MembersRepo"
import RefereeRolesRepo from "@repo/RefereeRolesRepo"
import RefereesRepo from "@repo/RefereesRepo"
import RefereeTitlesRepo from "@repo/RefereeTitlesRepo"
import SettingsRepo from "@repo/SettingsRepo"
import VenuesRepo from "@repo/VenuesRepo"
import { useRepo } from "pinia-orm"

// Complex updater called when data is necessary
export async function updater () {
    const settingsRepo = useRepo( SettingsRepo )

    // Just one run during the time (semaphore)
    if ( settingsRepo.getOption( Options.SailingDataLoadingState, Defaults.SailingDataLoadingState ) == 'true' )
        return

    settingsRepo.setOption( Options.SailingDataLoadingState, 'true' )

    // Start asynchronous processing of all catalogs and CTL (event list for this year), wait for every single to finish
    // First set - basic catalogs
    await Promise.allSettled( [
        useRepo( CountriesRepo ).update(),
        useRepo( ClubsRepo ).update(),
        useRepo( BoatClassesRepo ).update(),
        useRepo( VenuesRepo ).update(),
        useRepo( EventCoefficientsRepo ).update(),
        useRepo( RefereeTitlesRepo ).update(),
        useRepo( RefereeRolesRepo ).update(),
        useRepo( LifeguardRolesRepo ).update()
    ] )

    // Second set - dependant for existing ones
    await Promise.allSettled( [
        useRepo( RefereesRepo ).update(),
        useRepo( LifeguardsRepo ).update(),
        useRepo( MembersRepo ).update()
    ] )

    // Third set - dependant for existing ones again
    await Promise.allSettled( [
        useRepo( CtlRepo ).update()
    ] )

    // Last but not least - update timestamp in general store and clear loading semaphore
    settingsRepo.setOption( Options.LastSailingDataUpdated, new Date() )
    settingsRepo.setOption( Options.SailingDataLoadingState, 'false' )
}