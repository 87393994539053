import Event from "@/orm/models/Event"
import Referee from "@/orm/models/Referee"
import RefereeRole from "@/orm/models/RefereeRole"
import { Model } from 'pinia-orm'
import { BelongsTo, Num, Str, Uid } from 'pinia-orm/decorators'

export default class EventReferee extends Model {
    static entity = 'events-referees'

    @Uid() declare id: string
    @Uid() declare eventId: string
    @BelongsTo( () => Event, 'eventId' ) declare event: Event
    @Str( '', { notNullable: true } ) declare refereeId: string
    @BelongsTo( () => Referee, 'refereeId' ) declare referee: Referee
    @Num( 0, { notNullable: true } ) declare refereeRoleId: number
    @BelongsTo( () => RefereeRole, 'refereeRoleId' ) declare refereeRole: RefereeRole

    get dump () {
        return {
            refereeId: this.refereeId,
            refereeRoleId: this.refereeRoleId
        }
    }

    static piniaOptions = {
        persist: true
    }
}
