import { Model } from 'pinia-orm'
import { Num, Str } from 'pinia-orm/decorators'

const commonTypes = [ '1MI', '2KP', '3C', '3M', '4K', '7P', '8MZ', '8VC', '12MR' ]

export default class EventCoefficient extends Model {
    static entity = 'coefficients'

    @Str( '', { notNullable: true } ) declare id: string
    @Str( '', { notNullable: true } ) declare name: string
    @Str( null ) declare shortcut: string | null
    @Num( 0, { notNullable: true } ) declare value: number | null
    @Str( null ) declare note: string | null
    @Num( null ) declare raceMin: number | null
    @Num( null ) declare raceMax: number | null
    @Num( null ) declare weight: number | null

    get common () {
        return commonTypes.indexOf( this.id.toUpperCase() ) >= 0
    }

    static piniaOptions = {
        persist: true
    }
}