<template>
    <q-dialog ref="dialogRef">
        <q-card class="q-dialog-plugin">
            <q-card-section class="row items-center">
                {{ props.title }}
            </q-card-section>

            <q-card-section class="row items-center">
                <qe-form class="dialog-form">
                    <qe-input v-model="firstName" :label="ts( 'firstName' )" autofocus />
                    <qe-input v-model="lastName" :label="ts( 'lastName' )" />
                    <qe-input v-model="birthDate" type="date" :label="ts( 'birthDate' )" />
                    <qe-input v-model="email" type="email" :label="ts( 'email' )" />
                    <qe-input v-model="phone" :label="ts( 'phone' )" />
                    <div class="q-mt-sm">
                        {{ ts( 'sex' ) }}
                        <q-option-group
                            v-model="sex" :options="[ { value: 'male', label: ts( 'male' ) }, { value: 'female', label: ts( 'female' ) } ]" inline
                            @keydown.left="selectOtherSex" @keydown.right="selectOtherSex" />
                    </div>
                </qe-form>
            </q-card-section>

            <q-card-actions align="right">
                <q-btn flat :label="props.save" color="primary" @click="onSave" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script setup lang="ts">
import InfoBox from "@dialogs/InfoBox.vue"
import QeForm from "@qe/qeForm.vue"
import QeInput from "@qe/qeInput.vue"
import { useDialogPluginComponent, useQuasar } from "quasar"
import { ts } from "@/plugins/i18n-formatted"
import { ref } from "vue"

interface Props {
    title?: string
    save?: string
    color?: string
}

const props = withDefaults( defineProps<Props>(), {
    title: '',
    color: 'primary',
    save: () => ts( 'ok' )
} )

defineEmits( [ ...useDialogPluginComponent.emits ] )

const quasar = useQuasar()

const { dialogRef, onDialogOK } = useDialogPluginComponent()

const firstName = ref( '' )
const lastName = ref( '' )
const birthDate = ref( '' )
const sex = ref( 'male' )
const email = ref( '' )
const phone = ref( '' )

function selectOtherSex () {
    sex.value = sex.value === 'male' ? 'female' : 'male'
}

function onSave () {
    const currentYear = new Date().getFullYear()
    const oldestYear = currentYear - 115

    const birthYear = new Date( birthDate.value )?.getFullYear()

    if ( firstName.value === undefined || firstName.value.trim().length <= 1 || lastName.value === undefined || lastName.value.trim().length <= 1 || birthYear === undefined || birthYear == null || birthYear <= oldestYear || birthYear >= currentYear ) {
        quasar.dialog( {
            component: InfoBox,
            componentProps: {
                message: ts( 'missingPublicCrewRegData' )
            }
        } )

        return
    }

    onDialogOK( {
        firstName: firstName.value.trim(),
        lastName: lastName.value.trim(),
        birthDate: new Date( birthDate.value ),
        sex: sex.value,
        email: email.value.trim(),
        phone: phone.value.trim()
    } )
}
</script>

<style scoped lang="sass">
.dialog-form
  width: 100%
</style>