<template>
    <q-dialog ref="dialogRef" class="auto-width" @hide="onDialogHide">
        <q-card>
            <q-card-section class="items-center">
                <q-markup-table flat dense>
                    <thead>
                    <tr>
                        <th class="text-center">{{ ts( 'sel' ) }}</th>
                        <th class="text-center">{{ ts( 'change' ) }}</th>
                        <th class="text-left">{{ ts( 'boatClass' ) }}</th>
                        <th class="text-left">{{ ts( 'sailNo' ) }}</th>
                        <th class="text-left">{{ ts( 'crew' ) }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="change in changeList" :key="change.id">
                        <td class="text-center">
                            <q-checkbox v-model="change.selected"/>
                        </td>
                        <td class="text-center">
                            <q-icon name="add" color="positive" size="24px" v-if="change.operation === 'add'" />
                            <q-icon name="refresh" color="secondary" size="24px" v-else-if="change.operation === 'update'" />
                            <q-icon name="remove" color="negative" size="24px" v-if="change.operation === 'remove'" />
                        </td>
                        <td>
                            {{
                                allClasses.find( cl => cl.value === ( change.original !== undefined ? change.original.boatClassId : change.boat.boatClassId ) )?.label
                            }}
                        </td>
                        <td>
                            {{
                                change.original !== undefined ? change.original.country + " " + change.original.sailNumber : change.boat.country + " " + change.boat.sailNumber
                            }}
                        </td>
                        <td class="crew" v-if="change.operation === 'add'"><span>{{ crewOn( change.boat.crew ) }}</span></td>
                        <td class="crew" v-else-if="change.operation === 'remove'"><span>{{ crewOn( change.original.crew ) }}</span></td>
                        <td class="crew" v-else>
                            <span class="">{{ crewOn( change.original.crew ) }}</span>
                            <span class="q-ml-sm q-mr-sm"><q-icon class="col" name="arrow_forward" size="18px"/></span>
                            <span class="">{{ crewOn( change.boat.crew ) }}</span>
                        </td>
                    </tr>
                    </tbody>
                </q-markup-table>
            </q-card-section>

          <div class="row justify-between">
              <q-card-actions align="left">
                  <q-btn flat :label="ts( 'selectAll' )" color="secondary" @click="selectAll" />
                  <q-btn flat :label="ts( 'unselectAll' )" color="secondary" @click="unselectAll" />
              </q-card-actions>
              <q-card-actions align="right">
                  <q-btn flat :label="ts( 'close' )" color="primary" @click="onDialogCancel" />
                  <q-btn flat :label="ts( 'updateSelected' )" color="primary" @click="onSave" />
              </q-card-actions>
          </div>
        </q-card>
    </q-dialog>
</template>

<script setup lang="ts">
import nvl from '@/utils/nvl'
import BoatClassesRepo from "@repo/BoatClassesRepo"
import MembersRepo from "@repo/MembersRepo"
import { useRepo } from "pinia-orm"
import { useDialogPluginComponent } from "quasar"
import { ts } from "@/plugins/i18n-formatted"
import { rodnecislo } from 'rodnecislo'
import { ref } from "vue"

interface Props {
    color?: string
    changeList: object[]
}

const props = withDefaults( defineProps<Props>(), {
    icon: 'info',
    color: 'primary',
} )

const allClasses = ref( useRepo( BoatClassesRepo ).classesList() )

defineEmits( [ ...useDialogPluginComponent.emits ] )

const changeList = ref( props.changeList.map( c => ( { ...c, selected: c.operation === 'add' } ) ) )

function crewOn ( crew ) {
    return crew.sort( ( a, b ) => ( ( a.captain && !b.captain ) ? -1 : ( ( !a.captain && b.captain ) ? 1 : a.order - b.order ) ) )
        .map( c => {
            if ( c.memberId !== undefined )
                return nvl( useRepo( MembersRepo ).find( c.memberId )?.reverseFullNameWithBirthYearWithRegId, '' )
            else {
                const year = c.nonMemberBirthDate !== undefined
                             ? c.nonMemberBirthDate?.substring( 0, 4 )
                             : nvl( rodnecislo( c.nonMemberBirthCode )?.birthYear(), '' )

                return c.nonMemberRegId + ' ' + c.nonMemberLastName + ' ' + c.nonMemberFirstName
                       + ( year !== null ? ' (' + year + ')' : '' )
            }
        } ).join( ", " )
}

function selectAll () {
    changeList.value.forEach( c => { c.selected = true } )
}

function unselectAll () {
    changeList.value.forEach( c => { c.selected = false } )
}

function onSave () {
    onDialogOK( {
                  changeList: changeList.value
                                    .map( c => ( { selected: c.selected, data: JSON.stringify( c ) } ) )
                                    .filter( c => c.selected )
                                    .map( c => ( { ...JSON.parse( c.data ) } ) )
    } )
}

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
</script>

<style scoped lang="sass">
.crew span
  font-size: 80%

.auto-width > div > div
  max-width: none !important
</style>
