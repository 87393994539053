<template>
    <q-dialog ref="dialogRef">
        <q-card class="q-dialog-plugin">
            <q-card-section class="row items-center">
                {{ props.title }}
            </q-card-section>

            <q-card-section class="row items-center">
                <qe-form class="dialog-form">
                    <qe-input type=number v-model.number="attr" :label="props.label" :suffix="props.type === 'percent' ? ' %' : ''" autofocus />
                </qe-form>
            </q-card-section>

            <q-card-actions align="right">
                <q-btn flat :label="props.save" color="primary" @click="onSave" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script setup lang="ts">
import QeForm from "@qe/qeForm.vue"
import QeInput from "@qe/qeInput.vue"
import { useDialogPluginComponent } from "quasar"
import { ts } from "@/plugins/i18n-formatted"
import { ref } from "vue"

interface Props {
    title?: string
    label?: string
    value?: number
    type?: string
    save?: string
    color?: string
}

const props = withDefaults( defineProps<Props>(), {
    title: '',
    label: '',
    color: 'primary',
    value: 0,
    type: 'points',
    save: () => ts( 'ok' )
} )

defineEmits( [ ...useDialogPluginComponent.emits ] )

const { dialogRef, onDialogOK } = useDialogPluginComponent()

const attr = ref( props.value )

function onSave () {
    onDialogOK( {
        attr: attr.value
    } )
}
</script>

<style scoped lang="sass">
.dialog-form
  width: 100%
</style>