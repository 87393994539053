import Event from "@/orm/models/Event"
import nvl from "@/utils/nvl"
import EventRaceSkippedClass from "@model/EventRaceSkippedClass"
import EventResult from "@model/EventResult"
import { Model, useRepo } from 'pinia-orm'
import { BelongsTo, HasMany, Num, OnDelete, Uid } from 'pinia-orm/decorators'
import { implicitResult } from "@/orm/catalogs/OtherResults"

export default class EventRace extends Model {
    static entity = 'events-races'

    @Uid() declare id: string
    @Uid() declare eventId: string
    @BelongsTo( () => Event, 'eventId' ) declare event: Event
    @Num( 0, { notNullable: true } ) declare race: number
    @HasMany( () => EventResult, 'eventRaceId' ) @OnDelete( 'cascade' ) declare results: EventResult[]
    @HasMany( () => EventRaceSkippedClass, 'eventRaceId' ) @OnDelete( 'cascade' ) declare skippedClasses: EventRaceSkippedClass[]

    get dump () {
        return {
            race: this.race,
            results: this.results.map( ( result ) => result.dump ),
            skippedClasses: this.skippedClasses( ( skippedClass ) => skippedClass.dump )
        }
    }

    filteredResults ( boatClass = null ) {
        if ( this.results === undefined || this.results === null )
            return []

        return ( boatClass === null || boatClass === '-all' )
               ? this.results
               : this.results.filter( result => result?.boat?.boatClassId === boatClass )
    }

    orderedResults ( boatClass = null ) {
        return this.filteredResults( boatClass ).sort(
            ( a, b ) => ( a.boat?.boatClassId === b.boat?.boatClassId
                ? a.orderTo( b )
                : a.boat?.boatClass.name.localeCompare( b.boat?.boatClass.name ) )
        )
    }

    get anyResults (): boolean {
        return this.filteredResults().length > 0
    }

    totalBoats ( boatClass = null ) {
        // useRepo( Event ).find( this.eventId )
        return this.event.filteredBoats( ( boatClass === null || boatClass === '-all' ) ? null : boatClass ).length
    }

    allBoatsOrdered ( boatClass = null ) {
        const finishedBoats = this.filteredResults( boatClass ).map( r => r.eventBoatId )

        return this.event.filteredBoats( ( boatClass === null || boatClass === '-all' ) ? null : boatClass )
            .map( boat => ( { ...boat, sailNo: boat.sailNo, finished: finishedBoats.indexOf( boat.id ) >= 0 } ) )
            .sort( ( a, b ) => ( a.sailNumber - b.sailNumber ) )
    }

    finishedBoats ( boatClass = null ) {
        return this.filteredResults( boatClass ).length
    }

    totalBoatsWithoutSkipped ( boatClass = null ) {
        const skippedClasses = this.skippedClasses !== undefined ? this.skippedClasses.map( c => c.boatClassId ) : []

        // useRepo( Event ).find( this.eventId )
        return this.event
            .filteredBoats( ( boatClass === null || boatClass === '-all' ) ? null : boatClass )
            .filter( b => skippedClasses.indexOf( b.boatClassId ) === -1 ).length
    }

    unfinishedBoats ( boatClass = null ) {
        return this.totalBoatsWithoutSkipped( boatClass ) - this.finishedBoats( boatClass )
    }

    boatResult ( boatId ) {
        const results = this.results?.filter( result => result.eventBoatId === boatId )

        return results.length > 0 ? nvl( results[ 0 ].result, implicitResult ) : implicitResult
    }

    boatDecidedResult ( boatId ) {
        const results = this.results?.filter( result => result.eventBoatId === boatId )

        return results.length > 0 && results[ 0 ].result !== null && results[ 0 ].result !== undefined
    }

    boatPoints ( boatId, defaultPoints = 0 ) {
        const results = this.results?.filter( result => result.eventBoatId === boatId )

        return results.length > 0 ? nvl( results[ 0 ].points, defaultPoints ) : defaultPoints
    }

    isSkippedBoatClass ( boatClassId ) {
        return this.skippedClasses.find( skippedClass => skippedClass.boatClassId === boatClassId ) !== undefined
    }

    static piniaOptions = {
        persist: true
    }
}
