import { DateFormat, ds, ts } from '@/plugins/i18n-formatted'
    import Club from '@model/Club'
import EventBoat from '@model/EventBoat'
import EventClass from '@model/EventClass'
import EventLifeguard from '@model/EventLifeguard'
import EventRace from '@model/EventRace'
import EventReferee from '@model/EventReferee'
import Venue from '@model/Venue'
import { Model, useRepo } from 'pinia-orm'
import { DateCast } from 'pinia-orm/casts'
import { Attr, BelongsTo, Bool, Cast, HasMany, Num, OnDelete, Str } from 'pinia-orm/decorators'

export enum ResultsEnterModel {
    EnterNumByNum,
    EnterDirectClick,
    EnterBigField
}

export default class Event extends Model {
    static entity = 'events'

    @Num( 0, { notNullable: true } ) declare id: number
    @Str( '', { notNullable: true } ) declare title: string
    @Num( 0, { notNullable: true } ) declare ctlId: number
    @Cast( () => DateCast ) @Attr( '' ) declare from: string
    @Cast( () => DateCast ) @Attr( null ) declare to: string | null
    @Num( null ) declare clubId: number | null
    @BelongsTo( () => Club, 'clubId' ) declare club: Club | null
    @Str( null ) declare organizer: string | null
    @Num( null ) declare venueId: number | null
    @BelongsTo( () => Venue, 'venueId' ) declare venue: Venue | null
    @Str( null ) declare otherVenue: string | null
    @Str( null ) declare registrationUrl: string | null
    @Str( null ) declare noticeboardUrl: string | null
    @Str( null ) declare description: string | null
    @Str( null ) declare sponsor: string | null
    @HasMany( () => EventClass, 'eventId' ) @OnDelete( 'cascade' ) declare classes: EventClass[]
    @Str( null ) declare director: string | null
    @HasMany( () => EventReferee, 'eventId' ) @OnDelete( 'cascade' ) declare referees: EventReferee[]
    @HasMany( () => EventLifeguard, 'eventId' ) @OnDelete( 'cascade' ) declare lifeguards: EventLifeguard[]
    @HasMany( () => EventBoat, 'eventId' ) @OnDelete( 'cascade' ) declare boats: EventBoat[]
    @HasMany( () => EventRace, 'eventId' ) @OnDelete( 'cascade' ) declare races: EventRace[]
    @Num( null ) declare plannedRaces: number | null
    @Bool( false ) declare noLifeguards: boolean

    @Cast( () => DateCast ) @Attr( '' ) declare created: string
    @Cast( () => DateCast ) @Attr( '' ) declare lastOpened: string
    @Str( 'EventConfigurationTab' ) declare currentTab: string
    @Bool( false ) declare ignoreUpdateMembers: boolean
    @Num( ResultsEnterModel.EnterNumByNum ) declare currentResultsEnterMode: number

    get dump () {
        const current = useRepo( Event ).withAllRecursive().find( this.id )

        if ( current === null )
            return null

        return {
            ctl: current.ctlId,
            title: current.title,
            from: ds( current.from, DateFormat.SystemDate ),
            to: current.to === null ? null : ds( current.to, DateFormat.SystemDate ),
            clubId: current.clubId,
            organizer: current.organizer,
            venueId: current.venueId,
            otherVenue: current.otherVenue,
            registrationUrl: current.registrationUrl,
            noticeboardUrl: current.noticeboardUrl,
            description: current.description,
            sponsor: current.sponsor,
            director: current.director,
            plannedRaces: current.plannedRaces,
            created: ds( current.created, DateFormat.System ),
            lastOpened: ds( current.lastOpened, DateFormat.System ),
            ignoreUpdateMembers: current.ignoreUpdateMembers,
            noLifeguards: current.noLifeguards,
            classes: current.classes.map( ( cl ) => cl.dump ),
            referees: current.referees.map( ( referee ) => referee.dump ),
            lifeguards: current.lifeguards.map( ( lifeguard ) => lifeguard.dump ),
            boats: current.boats.map( ( boat ) => boat.dump ),
            races: current.races.map( ( race ) => race.dump ),
            _computed: current.classes.reduce( ( obj, cl ) => ( { ...obj, [ cl.boatClassId ]: this.results( cl.boatClassId ).map( res => {
                const classResults = res

                classResults.boat = { id: classResults.boat.id, country: classResults.boat.country, sailNumber: classResults.boat.sailNumber }

                return classResults
            } ) } ), {} )
        }
    }

    get fullTitle () {
        return ts( 'ctl', { ctlId: this.ctlId } ) + ' ' + this.title + ' (' + this.dateRange + ')'
    }

    get dateRange () {
        return ds( new Date( this.from ), ( this.to != null && !this.from.isSameAs( this.to ) ) ? DateFormat.EventFrom : DateFormat.EventTo )
            + ( ( this.to == null || this.from.isSameAs( this.to ) ) ? '' : ' – ' + ds( new Date( this.to ), DateFormat.EventTo ) )
    }

    get orderedClasses () {
        return this.classes.sort( ( a, b ) => ( a.weight == b.weight ? a.shortcut.localeCompare( b.shortcut ) : b.weight - a.weight ) )
    }

    get orderedClassesList () {
        return this.orderedClasses.map( ( eventClass ) => ( {
            value: eventClass.boatClass.id,
            label: eventClass.boatClass.name,
            shortLabel: eventClass.boatClass.shortcut
        } ) )
    }

    get orderedReferees () {
        return this.referees.sort( ( a, b ) => (
            a.refereeRole.points == b.refereeRole.points
                ? ( a.refereeRole.order === b.refereeRole.order
                        ? ( a.refereeRole.role === b.refereeRole.role
                                ? a.referee.reverseFullName.localeCompare( b.referee.reverseFullName )
                                : a.refereeRole.role.localeCompare( b.refereeRole.role )
                        )
                        : b.refereeRole.order - a.refereeRole.order
                )
                : b.refereeRole.points - a.refereeRole.points
        ) )
    }

    get orderedLifeguards () {
        return this.lifeguards.sort( ( a, b ) => ( a.lifeguardRole.points == b.lifeguardRole.points ? a.lifeguard.reverseFullName.localeCompare( b.lifeguard.reverseFullName ) : b.lifeguardRole.points - a.lifeguardRole.points ) )
    }

    filteredBoats ( boatClass = null ) {
        const eventId = this.id

        if ( boatClass === null ) {
            if ( this.boats !== undefined )
                return this.boats

            return useRepo( EventBoat ).withAllRecursive().where( 'eventId', eventId ).get()
        }

        return useRepo( EventBoat ).withAllRecursive().where( 'eventId', eventId ).where( 'boatClassId', boatClass ).get()
    }

    orderedBoats ( boatClass = null ) {
        const classesWeights = Object.fromEntries( this.classes.map( eventClass => ( [ eventClass.boatClass.id, eventClass.weight ] ) ) )

        return this.filteredBoats( boatClass ).sort( ( a, b ) => ( classesWeights[ a.boatClass.id ] == classesWeights[ b.boatClass.id ]
            ? ( a.boatClass.id === b.boatClass.id ? a.sailNo.localeCompare( b.sailNo ) : a.boatClass.name.localeCompare( b.boatClass.name ) )
            : ( classesWeights[ b.boatClass.id ] - classesWeights[ a.boatClass.id ] ) ) )
    }

    availableRaces ( boatClass = null ) {
        let races = useRepo( EventRace ).withAllRecursive().where( 'eventId', this.id ).get()

        if ( boatClass !== null ) {
            races = races.filter( race => race.results.filter( result => result.boat.boatClassId === boatClass ).length > 0 )
        }

        return races.sort( ( a, b ) => ( a.race - b.race ) )
    }

    unfinishedBoats ( race = null, boatClass = null ) {
        const finishedBoats = race !== null ? this.races.filter( er => er.race === race.race )[ 0 ]?.results.map( result => result.boat.id ) : []
        const skippedClasses = race !== null ? race.skippedClasses.map( c => c.boatClassId ) : []

        return this.orderedBoats( boatClass )
            .filter( boat => finishedBoats.indexOf( boat.id ) === -1 && skippedClasses.indexOf( boat.boatClassId ) === -1 )
            .map( ( boat ) => ( {
                value: boat.id,
                label: boat.sailNo
                    + ( ( boat.boatClass.useBoatNames && boat.name !== null && boat.name !== undefined && boat.name.length > 0 ) ? ' ' + boat.name : '' )
                    + ( boatClass === null ? ' (' + boat.boatClass.name + ')' : '' )
                    + ( boat.captain !== null ? ' – kpt. ' + boat.captain.reverseFullName : '' )
            } ) )
    }

    getClass ( boatClass ) {
        const eventId = this.id

        if ( this.classes === undefined )
            return useRepo( EventClass ).withAllRecursive().where( 'eventId', eventId ).where( 'boatClassId', boatClass ).first()

        const foundClass = this.classes.filter( cl => cl.boatClassId === boatClass )

        if ( foundClass.length === 0 )
            return null

        return foundClass[ 0 ]
    }

    get venueTitle () {
        return this.venueId != null ? this.venue?.localizedName : this.otherVenue
    }

    get clubTitle () {
        return this.clubId != null ? this.club?.id + ' ' + this.club?.name : this.organizer
    }

    get clubTitleShort () {
        return this.clubId != null ? this.club?.id + ' ' + this.club?.shortcut : this.organizer
    }

    get mainReferee () {
        const eventReferee = useRepo( EventReferee ).where( 'eventId', this.id ).where( 'refereeRoleId', 1 ).with( 'referee' ).first()

        if ( eventReferee === null )
            return null

        return eventReferee.referee
    }

    classAttended ( boatClassId ) {
        return this.classes.find( ( eventClass ) => ( eventClass.boatClassId === boatClassId ) )
    }

    results ( boatClass ) {
        if ( boatClass === null )
            return []

        const eventClass = this.getClass( boatClass )

        if ( eventClass === null )
            return []

        const discarded = eventClass.discarded

        return useRepo( EventBoat ).withAllRecursive().where( 'eventId', this.id ).where( 'boatClassId', boatClass ).get().map( boat => {
            const discardedRaces = boat.discardedRaces( discarded )

            const results = boat.results.map( result => {
                result.isDiscarded = discardedRaces.indexOf( result.race ) >= 0

                return result
            } )

            return {
                boat: boat,
                results: results,
                points: results.reduce( ( sum, race ) => sum + ( race.isDiscarded ? 0 : race.points ), 0 ),
                a81: results.filter( race => !race.isDiscarded ).map( race => race.points ).sort( ( a, b ) => ( a - b ) ).reduce( ( results, result ) => results + ' ' + result, '' ).trim(),
                a82: results.map( race => race.points ).reduce( ( results, result ) => result + ' ' + results, '' ).trim()
            }
        } ).sort( ( a, b ) => ( a.points !== b.points ? a.points - b.points : ( a.a81 !== b.a81 ? a.a81.localeCompare( b.a81 ) : a.a82.localeCompare( b.a82 ) ) ) )
    }

    static piniaOptions = {
        persist: true
    }
}
