<template>
    <div class="q-pa-md q-gutter-sm">
        <q-breadcrumbs>
            <q-breadcrumbs-el icon="home" to="/" />
            <q-breadcrumbs-el :label="props.label" />
        </q-breadcrumbs>
    </div>
</template>

<script setup lang="ts">
interface Props {
    label: string
}

const props = withDefaults( defineProps<Props>(), {
    label: ''
} )
</script>