<template>
    <q-btn
        ref="innerBtn"
        outline color="primary" class="qe"
        @click="formRepeat" />
</template>

<script setup lang="ts">
import { inject, ref } from "vue"

const innerBtn = ref()

const focusResetCounter = inject( 'focusResetCounter' )

const emits = defineEmits( [ 'click' ] )

const focus = () => {
    innerBtn.value.focus()
}

defineExpose( { focus } )

function formRepeat ( event, go ) {
    emits( 'click', event, go )

    focusResetCounter.value++
}
</script>