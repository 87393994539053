<template>
    <q-select
        ref="innerSelect" option-value="value" option-label="label" class="qe"
        use-input emit-value map-options input-debounce="300" hide-selected fill-input
        @popup-hide.native="changed()">
        <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope || {}" />
        </template>
    </q-select>
</template>

<script setup lang="ts">
import { QSelect } from "quasar"
import { inject, ref } from "vue"

const innerSelect = ref()

const focusNextCounter = inject( 'focusNextCounter' )

const focus = () => {
    innerSelect.value.focus()
}

defineExpose( { focus } )

function changed () {
    focusNextCounter.value++
}
</script>