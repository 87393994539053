<template>
    <q-dialog ref="dialogRef" @hide="onDialogHide">
        <q-card class="q-dialog-plugin">
            <q-card-section class="row items-center">
                <div class="col-2 q-mr-md">
                    <q-avatar :icon="props.icon" :color="props.color" text-color="white" />
                </div>
                <span class="col-9 q-ml-sm">{{ props.message }}</span>
            </q-card-section>

            <q-card-actions align="right">
                <q-btn flat :label="props.close" color="primary" @click="onDialogOK" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script setup lang="ts">
import { useDialogPluginComponent } from "quasar"
import { ts } from "@/plugins/i18n-formatted"

interface Props {
    icon?: string
    message: string
    close?: string
    color?: string
}

const props = withDefaults( defineProps<Props>(), {
    icon: 'info',
    color: 'primary',
    close: () => ts( 'close' )
} )

defineEmits( [ ...useDialogPluginComponent.emits ] )

const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent()
</script>