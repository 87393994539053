import BoatClass from "@/orm/models/BoatClass"
import Ctl from "@/orm/models/Ctl"
import { Model } from 'pinia-orm'
import { BelongsTo, Num, Str } from 'pinia-orm/decorators'

export default class CtlClass extends Model {
    static entity = 'ctl-classes'

    @Str( '', { notNullable: true } ) declare id: string
    @Num( 0, { notNullable: true } ) declare ctlId: number
    @BelongsTo( () => Ctl, 'ctlId' ) declare ctl: Ctl
    @Num( 0, { notNullable: true } ) declare boatClassId: number
    @BelongsTo( () => BoatClass, 'boatClassId' ) declare boatClass: BoatClass
    @Num( 0, { notNullable: true } ) declare weight: number
    @Str( null ) declare eventType: string | null
    @Str( '', { notNullable: true } ) declare shortcut: string

    static piniaOptions = {
        persist: true
    }
}