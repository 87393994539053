<template>
    <q-tab-panel name="EventConfigurationTab">
        <q-card-section v-if="editMode == EditMode.EditDetails">
            <qe-form>
                <qe-input
                    v-model="ctlEditable" autofocus clearable :label="ts( 'ctlId' )" :rules="[ ( val ) => ( val >= ctlMin && val <= ctlMax ) || ctlBadId ]"
                    maxlength="6" inputmode="numeric" />

                <qe-input v-model="titleEditable" type="text" :label="ts( 'eventTitle' )" />
                <qe-input v-model="eventFromEditable" type="date" :label="ts( 'eventFrom' )" />
                <qe-input v-model="eventToEditable" type="date" :label="ts( 'eventTo' )" />

                <qe-combo-box
                    v-model="venueEditable" :options="venuesFilteredList" :label="ts( 'venue' )" clearable
                    @filter="venueFilter" />

                <qe-input v-model="otherVenueEditable" type="text" :label="ts( 'otherVenue' )" v-if="venueEditable === null" />

                <qe-combo-box
                    v-model="clubEditable" :options="clubsFilteredList" :label="ts( 'organizer' )" clearable
                    @filter="clubFilter" />

                <qe-input v-model="otherOrganizerEditable" type="text" :label="ts( 'otherOrganizer' )" v-if="clubEditable === null" />

                <qe-input v-model.number="plannedRacesEditable" type="number" :label="ts( 'plannedRaces' )" />
                <qe-input v-model="directorEditable" type="text" :label="ts( 'director' )" />
                <qe-input v-model="sponsorEditable" type="text" :label="ts( 'sponsor' )" />
                <qe-input v-model="descEditable" type="textarea" :label="ts( 'desc' )" />
            </qe-form>

            <q-card-actions align="right" class="q-mt-md">
                <q-btn
                    :label="ts( 'cancel' )" outline
                    @click="editMode = EditMode.Display" />
                <q-btn
                    :label="ts( 'save' )" outline
                    @click="saveValues()" />
            </q-card-actions>
        </q-card-section>

        <q-card-section v-else-if="editMode == EditMode.EditClasses">
            <qe-form>
                <q-table flat :rows="attendedClasses" :columns="classColumns" row-key="id" binary-state-sort :no-data-label="ts( 'noClasses' )"
                         :rows-per-page-options="[0,5,10]">
                    <template v-slot:body-cell-actions="props">
                        <q-td :props="props">
                            <q-btn
                                flat icon="delete" color="negative" class="qe-ignore-focus"
                                @click="removeClass( props.row )" />
                        </q-td>
                    </template>
                    <template v-slot:body-cell-eventType="props">
                        <q-td :props="props" class="cursor-pointer">
                            {{ props.row.eventTypeDesc }}
                            <q-popup-edit
                                v-model="props.row.eventTypeNormalized" v-slot="scope" buttons cover
                                @save="( val ) => ( setBoatClass( val, props.row ) )">
                                <q-select
                                    v-model="scope.value" autofocus :options="eventTypesList"
                                    :label="ts( 'eventTypeCoefFor', { boatClass: props.row.boatClass.name } )" option-value="value"
                                    option-label="label" emit-value map-options input-debounce="300"
                                    @keyup.enter="scope.set">
                                    <template v-slot:option="{ itemProps, opt }">
                                        <q-item v-bind="itemProps" :class="opt.lastCommon ? 'group-separated-option' : ''">
                                            <q-item-section>
                                                <q-item-label v-html="opt.label" />
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-select>
                            </q-popup-edit>
                        </q-td>
                    </template>
                    <template v-slot:body-cell-discards="props">
                        <q-td :props="props" class="cursor-pointer">
                            {{ props.row.discardDefinition }}
                            <q-popup-edit
                                v-model="props.row.discardDefinition" v-slot="scope" buttons cover
                                @save="( val )=> ( setBoatDiscards( val, props.row ) )">
                                <q-input v-model="scope.value" autofocus @keyup.enter="scope.set" />
                            </q-popup-edit>
                        </q-td>
                    </template>
                    <template v-slot:bottom-row>
                        <q-td>
                            <qe-combo-box
                                v-model="newBoatClass" :label="ts( 'boatClass' )" :options="boatClassFilteredList" autofocus
                                @filter="boatClassesFilter" />
                        </q-td>
                        <q-td>
                            <qe-combo-box
                                v-model="newEventType" :label="ts( 'eventTypeCoef' )" :options="eventTypesList">
                                <template v-slot:option="{ itemProps, opt }">
                                    <q-item v-bind="itemProps" :class="opt.lastCommon ? 'group-separated-option' : ''">
                                        <q-item-section>
                                            <q-item-label v-html="opt.label" />
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </qe-combo-box>
                        </q-td>
                        <q-td class="text-center">
                            <qe-btn
                                :label="ts( 'add' )" color="secondary"
                                @click="addNewClass()" />
                        </q-td>
                    </template>
                </q-table>
            </qe-form>

            <q-card-actions align="right" class="q-mt-md">
                <q-btn outline color="primary" :label="ts( 'close' )"
                       @click="editMode = EditMode.Display" />
            </q-card-actions>
        </q-card-section>

        <q-card-section v-else-if="editMode == EditMode.EditReferees">
            <qe-form>
                <q-table flat :rows="attendedReferees" :columns="refereeColumns" row-key="id" binary-state-sort :no-data-label="ts( 'noReferees' )"
                         :rows-per-page-options="[0,5,10,15]">
                    <template v-slot:body-cell-actions="props">
                        <q-td :props="props">
                            <q-btn
                                flat icon="delete" color="negative" class="qe-ignore-focus"
                                @click="removeReferee( props.row )" />
                        </q-td>
                    </template>
                    <template v-slot:bottom-row>
                        <q-td />
                        <q-td>
                            <qe-combo-box
                                v-model="newReferee" autofocus :options="refereeFilteredList" :label="ts( 'referee' )"
                                @filter="refereeFilter"
                                @keydown.enter="chooseOnlyOneReferee()" />
                        </q-td>
                        <q-td />
                        <q-td>
                            <qe-combo-box
                                v-model="newRefereeRole" :label="ts( 'refereeRole' )" :options="refereeRolesList" />
                        </q-td>
                        <q-td class="text-center">
                            <qe-btn
                                :label="ts( 'add' )" color="secondary"
                                @click="addNewReferee()" />
                        </q-td>
                    </template>
                </q-table>
            </qe-form>

            <q-card-actions align="right" class="q-mt-md">
                <q-btn outline color="primary" :label="ts( 'close' )"
                       @click="editMode = EditMode.Display" />
            </q-card-actions>
        </q-card-section>

        <q-card-section v-else-if="editMode == EditMode.EditLifeguards">
            <qe-form>
                <q-checkbox v-model="noLifeguards" :label="ts( 'eventWithoutLifeguards' )" @update:model-value="saveNoLifeguards" />

                <q-table flat :rows="attendedLifeguards" :columns="lifeguardColumns" row-key="id" binary-state-sort :no-data-label="ts( 'noLifeguards' )"
                         class="q-mt-md" :rows-per-page-options="[0,5,10,15]" v-if="!noLifeguards">
                    <template v-slot:body-cell-actions="props">
                        <q-td :props="props">
                            <q-btn
                                flat icon="delete" color="negative" class="qe-ignore-focus"
                                @click="removeLifeguard( props.row )" />
                        </q-td>
                    </template>
                    <template v-slot:bottom-row>
                        <q-td />
                        <q-td>
                            <qe-combo-box
                                v-model="newLifeguard" autofocus :options="lifeguardFilteredList" :label="ts( 'lifeguard' )"
                                @filter="lifeguardFilter"
                                @keydown.enter="chooseOnlyOneLifeguard()" />
                        </q-td>
                        <q-td>
                            <qe-combo-box
                                v-model="newLifeguardRole" :label="ts( 'lifeguardRole' )" :options="lifeguardRolesList" />
                        </q-td>
                        <q-td class="text-center">
                            <qe-btn
                                :label="ts( 'add' )" color="secondary"
                                @click="addNewLifeguard()" />
                        </q-td>
                    </template>
                </q-table>
            </qe-form>

            <q-card-actions align="right" class="q-mt-md">
                <q-btn outline color="primary" :label="ts( 'close' )"
                       @click="editMode = EditMode.Display" />
            </q-card-actions>
        </q-card-section>

        <div v-else>
            <q-card-section>
                <q-markup-table separator="none" flat>
                    <tbody>
                    <tr>
                        <td>{{ ts( 'ctlId' ) }}</td>
                        <td>{{ event.ctlId }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'eventTitle' ) }}</td>
                        <td>{{ event.title }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'eventTerm' ) }}</td>
                        <td>{{ event.dateRange }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'venue' ) }}</td>
                        <td>{{ event.venueTitle }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'organizer' ) }}</td>
                        <td>{{ event.clubTitle }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'sponsor' ) }}</td>
                        <td>{{ event.sponsor }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'director' ) }}</td>
                        <td>{{ event.director }}</td>
                    </tr>
                    <tr v-if="event.description != null && event.description != ''">
                        <td>{{ ts( 'desc' ) }}</td>
                        <td>{{ event.description }}</td>
                    </tr>
                    </tbody>
                </q-markup-table>
            </q-card-section>

            <q-card-actions align="right" class="q-mt-md">
                <q-btn
                    outline color="primary" :label="ts( 'editEventDetails' )"
                    @click="editDetails()" />
            </q-card-actions>

            <q-separator />

            <q-card-section>
                <q-markup-table separator="vertical" :wrap-cells="false" flat bordered class="q-mt-lg classlist" v-if="event.orderedClasses.length > 0">
                    <tbody>
                    <tr>
                        <td v-for="eventClass in event.orderedClasses" :style="'width: ' + ( 100 / event.orderedClasses.length ) + '%'" :key="eventClass.id">
                            <div class="row">
                                <div class="col">
                                    {{ eventClass.boatClass?.name }}
                                </div>
                                <div class="col text-right">
                                    {{ eventClass.weight }}{{ eventClass.eventType != null ? eventClass.eventType : '' }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td v-for="eventClass in event.orderedClasses" :key="eventClass.id">
                            <div class="row">
                                <div :class="eventClass.areAllBoatsValid ? 'col' : 'col issue'">
                                    {{ tcs( "boats", eventClass.totalBoats ) }}
                                </div>
                                <div class="col text-right">
                                    {{ tcs( "races", eventClass.raced ) }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </q-markup-table>
                <div v-else>
                    {{ ts( 'noClasses' ) }}
                </div>
            </q-card-section>

            <q-card-actions align="right" class="q-mt-md">
                <q-btn outline color="primary" :label="ts( 'editEventClasses' )"
                       @click="editMode = EditMode.EditClasses" />
            </q-card-actions>

            <q-separator />

            <q-card-section>
                <q-list dense bordered padding class="q-mt-lg" v-if="event.referees.length > 0">
                    <q-item
                        v-for="eventReferee in event.orderedReferees" :key="eventReferee.id"
                        :class="eventReferee.refereeRole.isMainReferee ? 'main-referee' : ''">
                        <q-item-section>
                            {{ eventReferee.referee.reverseFullNameWithIdentification }}
                        </q-item-section>
                        <q-item-section>
                            {{ eventReferee.refereeRole.role }}
                        </q-item-section>
                        <q-item-section class="col-phone">
                            <span v-html="nvl( eventReferee.referee.phone.formatPhone(), '' )" />
                        </q-item-section>
                        <q-item-section class="col-email">
                            <span v-html="nvl( eventReferee.referee.email.formatEmail(), '' )" />
                        </q-item-section>
                    </q-item>
                </q-list>
                <div v-else>
                    {{ ts( 'noReferees' ) }}
                </div>
            </q-card-section>

            <q-card-actions align="right" class="q-mt-md">
                <q-btn
                    outline color="primary" :label="ts( 'editEventReferees' )"
                    @click="editMode = EditMode.EditReferees" />
            </q-card-actions>

            <q-separator />

            <q-card-section>
                <div v-if="event.noLifeguards">
                    {{ ts( 'eventWithoutLifeguards' ) }}
                </div>

                <q-list dense bordered padding class="q-mt-lg" v-else-if="event.lifeguards.length > 0">
                    <q-item
                        v-for="eventLifeguard in event.orderedLifeguards" :key="eventLifeguard.id"
                        :class="eventLifeguard.lifeguardRole.isMainLifeguard ? 'main-lifeguard' : ''">
                        <q-item-section>
                            {{ eventLifeguard.lifeguard.reverseFullNameWithIdentification }}
                        </q-item-section>
                        <q-item-section>
                            {{ eventLifeguard.lifeguardRole.role }}
                        </q-item-section>
                        <q-item-section class="col-phone">
                            <span v-html="eventLifeguard.lifeguard.phone.formatPhone()" />
                        </q-item-section>
                        <q-item-section class="col-email">
                            <span v-html="eventLifeguard.lifeguard.email.formatEmail()" />
                        </q-item-section>
                    </q-item>
                </q-list>
                <div v-else>
                    {{ ts( 'noLifeguards' ) }}
                </div>
            </q-card-section>

            <q-card-actions align="right" class="q-mt-md">
                <q-btn outline color="primary" :label="ts( 'editEventLifeguards' )"
                       @click="editMode = EditMode.EditLifeguards" />
            </q-card-actions>
        </div>
    </q-tab-panel>
</template>

<script setup lang="ts">
import nvl from "@/utils/nvl"
import Confirmation from "@dialogs/Confirmation.vue"
import InfoBox from "@dialogs/InfoBox.vue"
import Sandbox from "@nyariv/sandboxjs"
import QeBtn from "@qe/qeBtn.vue"
import QeComboBox from "@qe/qeComboBox.vue"
import QeForm from "@qe/qeForm.vue"
import QeInput from "@qe/qeInput.vue"
import EventClass from "@model/EventClass"
import EventLifeguard from "@model/EventLifeguard"
import EventReferee from "@model/EventReferee"
import BoatClassesRepo from "@repo/BoatClassesRepo"
import ClubsRepo from "@repo/ClubsRepo"
import EventsRepo from "@repo/EventsRepo"
import LifeguardRolesRepo from "@repo/LifeguardRolesRepo"
import LifeguardsRepo from "@repo/LifeguardsRepo"
import MembersRepo from "@repo/MembersRepo"
import RefereeRolesRepo from "@repo/RefereeRolesRepo"
import RefereesRepo from "@repo/RefereesRepo"
import VenuesRepo from "@repo/VenuesRepo"
import EventCoefficientsRepo from "@repo/EventCoefficientsRepo"
import { ts, tcs, ns } from "@/plugins/i18n-formatted"
import { useRepo } from "pinia-orm"
import { useQuasar } from "quasar"
import { computed, onMounted, ref } from "vue"
import { useRoute } from "vue-router"

enum EditMode {
    Display,
    EditDetails,
    EditClasses,
    EditReferees,
    EditLifeguards
}

const quasar = useQuasar()
const route = useRoute()

const emits = defineEmits( [ 'logInAndUpdate' ] )

const editMode = ref( EditMode.Display )

// CTL limits
const today = new Date()
const ctlMin = ( today.getFullYear() - 2000 ) * 10000
const ctlMax = ctlMin + 9999
const ctlBadId = ts( 'badCtlId' )

// Data layer
const ctlEditable = ref()
const titleEditable = ref()
const eventFromEditable = ref()
const eventToEditable = ref()
const venueEditable = ref()
const clubEditable = ref()
const directorEditable = ref()
const sponsorEditable = ref()
const descEditable = ref()
const plannedRacesEditable = ref()
const otherVenueEditable = ref()
const otherOrganizerEditable = ref()

const newBoatClass = ref()
const newEventType = ref()
const newReferee = ref()
const newRefereeRole = ref()
const newLifeguard = ref()
const newLifeguardRole = ref()

const membersRepo = computed( () => ( useRepo( MembersRepo ) ) )
const venuesRepo = computed( () => ( useRepo( VenuesRepo ) ) )
const clubsRepo = computed( () => ( useRepo( ClubsRepo ) ) )
const classesRepo = computed( () => ( useRepo( BoatClassesRepo ) ) )
const eventCoefficientsRepo = computed( () => ( useRepo( EventCoefficientsRepo ) ) )
const refereeRepo = computed( () => ( useRepo( RefereesRepo ) ) )
const refereeRolesRepo = computed( () => ( useRepo( RefereeRolesRepo ) ) )
const lifeguardRepo = computed( () => ( useRepo( LifeguardsRepo ) ) )
const lifeguardRolesRepo = computed( () => ( useRepo( LifeguardRolesRepo ) ) )

const event = computed( () => ( useRepo( EventsRepo ).getEvent( String( route.params.eventId ), true ) ) )

useRepo( EventsRepo ).selectEvent( event.value.id )

const venuesFilteredList = ref( venuesRepo.value.venuesList( true ) )
const clubsFilteredList = ref( clubsRepo.value.clubsList( true ) )
const eventTypesList = ref( eventCoefficientsRepo.value.eventCoefficientsList() )
const refereeRolesList = ref( refereeRolesRepo.value.orderedList() )
const refereeFilteredList = ref( refereeRepo.value.orderedList( event.value ) )
const lifeguardRolesList = ref( lifeguardRolesRepo.value.orderedList() )
const lifeguardFilteredList = ref( lifeguardRepo.value.orderedList( event.value ) )

const attendedClasses = computed( () => ( event.value.orderedClasses ) )
const attendedReferees = computed( () => ( event.value.orderedReferees ) )
const attendedLifeguards = computed( () => ( event.value.orderedLifeguards ) )
const boatClassFilteredList = ref( classesRepo.value.classesList( event.value ) )

const noLifeguards = ref( event.value.noLifeguards )

const classColumns = ref( [
    {
        name: 'className',
        required: true,
        label: ts( 'boatClass' ),
        align: 'left',
        field: row => row.boatClass?.name,
        sortable: true
    },
    {
        name: 'eventType',
        label: ts( 'eventTypeCoef' ),
        align: 'left',
        field: row => row.eventTypeDesc,
        sortable: true
    },
    {
        name: 'plannedWeight',
        required: true,
        label: ts( 'plannedWeight' ),
        align: 'center',
        field: row => row.weight,
        sortable: true,
        sortOrder: 'desc',
        sort: ( a, b ) => parseInt( a ) - parseInt( b )
    },
    {
        name: 'boats',
        label: ts( 'boatsHeader' ),
        align: 'center',
        field: row => row.totalBoats,
        sortable: false
    },
    {
        name: 'raced',
        label: ts( 'raced' ),
        align: 'center',
        field: row => row.raced,
        sortable: false
    },
    {
        name: 'weight',
        label: ts( 'weight' ),
        align: 'center',
        field: row => row.raced > 0 ? row.calculatedWeight : '',
        sortable: true,
        sortOrder: 'desc',
        sort: ( a, b ) => parseInt( a ) - parseInt( b )
    },
    {
        name: 'discards',
        label: ts( 'discards' ),
        align: 'center',
        field: row => row.discardDefinition,
        sortable: false
    },
    {
        name: 'actions',
        label: ts( 'actions' ),
        field: () => '',
        align: 'center'
    }
] )

const refereeColumns = ref( [
    {
        name: 'refereeId',
        required: true,
        label: ts( 'refereeId' ),
        align: 'left',
        field: row => 'R' + row.referee.id,
        sortable: false
    },
    {
        name: 'referee',
        required: true,
        label: ts( 'referee' ),
        align: 'left',
        field: row => row.referee.reverseFullName,
        sortable: true
    },
    {
        name: 'refereeTitles',
        required: true,
        label: ts( 'refereeTitles' ),
        align: 'left',
        field: row => row.referee.identification,
        sortable: false
    },
    {
        name: 'refereeRole',
        required: true,
        label: ts( 'refereeRole' ),
        align: 'left',
        field: row => row.refereeRole.role,
        sortable: true
    },
    {
        name: 'weight',
        required: true,
        label: ts( 'weight' ),
        align: 'right',
        field: row => ns( row.refereeRole.points ),
        sortable: true,
        sortOrder: 'desc',
        sort: ( a, b ) => parseFloat( a ) - parseFloat( b )
    },
    {
        name: 'actions',
        label: ts( 'actions' ),
        field: () => '',
        align: 'center'
    }
] )

const lifeguardColumns = ref( [
    {
        name: 'lifeguardId',
        required: true,
        label: ts( 'lifeguardId' ),
        align: 'left',
        field: row => 'L' + row.lifeguard.id,
        sortable: false
    },
    {
        name: 'lifeguard',
        required: true,
        label: ts( 'lifeguard' ),
        align: 'left',
        field: row => row.lifeguard.reverseFullName,
        sortable: true
    },
    {
        name: 'lifeguardRole',
        required: true,
        label: ts( 'lifeguardRole' ),
        align: 'left',
        field: row => row.lifeguardRole.role,
        sortable: true
    },
    {
        name: 'weight',
        required: true,
        label: ts( 'weight' ),
        align: 'right',
        field: row => ns( row.lifeguardRole.points ),
        sortable: true,
        sortOrder: 'desc',
        sort: ( a, b ) => parseFloat( a ) - parseFloat( b )
    },
    {
        name: 'actions',
        label: ts( 'actions' ),
        field: () => '',
        align: 'center'
    }
] )

function saveNoLifeguards () {
    useRepo( EventsRepo ).save( { id: event.value.id, noLifeguards: noLifeguards.value } )
}

function addNewClass () {
    if ( newBoatClass.value != null && newEventType.value != null ) {
        const eventClass = useRepo( EventClass ).save( {
            eventId: event.value.id,
            boatClassId: newBoatClass.value,
            weight: 0,
            shortcut: ''
        } )

        useRepo( EventClass ).withAll().find( eventClass.id ).eventTypeNormalized = newEventType.value

        newBoatClass.value = null
        newEventType.value = null
    }
}

function setBoatClass ( normalizedType, eventClass ) {
    eventClass.eventTypeNormalized = normalizedType
}

function setBoatDiscards ( discardDefinition, eventClass ) {
    const definition = discardDefinition.trim()

    let check = definition.indexOf( ',' ) >= 0
        ? ( definition.match( /^(\d,\s*)*\d$/ ) || definition.match( /^(R\d+,\s*)*R\d+$/ ) )
        : ( definition.charAt( 0 ) === 'R' ? !isNaN( definition.substring( 1 ) ) : undefined )

    if ( check === undefined ) {
        const sandbox = new Sandbox()
        const exec = sandbox.compile( 'return ' + definition )

        const res = exec( { s: 1, r: 1 } ).run()

        check = !isNaN( res )
    }

    if ( !check ) {
        quasar.dialog( {
            component: InfoBox,
            componentProps: {
                color: 'negative',
                message: ts( 'invalidDiscardExpression', { expr: discardDefinition } )
            }
        } )

        return
    }

    useRepo( EventClass ).save( { id: eventClass.id, discardDefinition: discardDefinition } )
}

function removeClass ( eventClass ) {
    quasar.dialog( {
            component: Confirmation,
            componentProps: {
                icon: 'delete',
                question: ts( 'removeBoatClassFromEvent', { eventTitle: eventClass.event?.fullTitle, boatClass: eventClass.boatClass?.name } )
            }
        } )
        .onOk( () => {
            useRepo( EventClass ).destroy( eventClass.id )
        } )
}

function refereeFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            refereeFilteredList.value = refereeRepo.value.orderedList( event.value )
        } )
        return
    }

    update( () => {
        const needle = val.toSearchable()
        refereeFilteredList.value = refereeRepo.value.orderedList( event.value ).filter( referee => referee.searchLabel.toSearchable().indexOf( needle ) > -1 )
    } )
}

function addNewReferee () {
    if ( newReferee.value != null && newRefereeRole.value != null ) {
        useRepo( EventReferee ).save( {
            eventId: event.value.id,
            refereeId: newReferee.value,
            refereeRoleId: newRefereeRole.value
        } )

        newReferee.value = null
        newRefereeRole.value = null
    }
}

function removeReferee ( referee ) {
    quasar.dialog( {
            component: Confirmation,
            componentProps: {
                icon: 'delete',
                question: ts( 'removeRefereeFromEvent', { eventTitle: referee.event?.fullTitle, refereeName: referee.referee?.reverseFullNameWithIdentification } )
            }
        } )
        .onOk( () => {
            useRepo( EventReferee ).destroy( referee.id )
        } )
}

function chooseOnlyOneReferee () {
    if ( refereeFilteredList.value != null && refereeFilteredList.value.length == 1 )
        newReferee.value = refereeFilteredList.value[ 0 ].value
}

function lifeguardFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            lifeguardFilteredList.value = lifeguardRepo.value.orderedList( event.value )
        } )
        return
    }

    update( () => {
        const needle = val.toSearchable()
        lifeguardFilteredList.value = lifeguardRepo.value.orderedList( event.value ).filter( lifeguard => lifeguard.searchLabel.toSearchable().indexOf( needle ) > -1 )
    } )
}

function addNewLifeguard () {
    if ( newLifeguard.value != null && newLifeguardRole.value != null ) {
        useRepo( EventLifeguard ).save( {
            eventId: event.value.id,
            lifeguardId: newLifeguard.value,
            lifeguardRoleId: newLifeguardRole.value
        } )

        newLifeguard.value = null
        newLifeguardRole.value = null
    }
}

function removeLifeguard ( lifeguard ) {
    quasar.dialog( {
            component: Confirmation,
            componentProps: {
                icon: 'delete',
                question: ts( 'removeLifeguardFromEvent', {
                    eventTitle: lifeguard.event?.fullTitle,
                    lifeguardName: lifeguard.lifeguard?.reverseFullNameWithIdentification
                } )
            }
        } )
        .onOk( () => {
            useRepo( EventReferee ).destroy( lifeguard.id )
        } )
}

function chooseOnlyOneLifeguard () {
    if ( lifeguardFilteredList.value != null && lifeguardFilteredList.value.length == 1 )
        newLifeguard.value = lifeguardFilteredList.value[ 0 ].value
}

function editDetails () {
    ctlEditable.value = event.value.ctlId
    titleEditable.value = event.value.title
    eventFromEditable.value = new Date( event.value.from ).toSimpleDate()
    eventToEditable.value = new Date( event.value.to ).toSimpleDate()
    venueEditable.value = event.value.venueId
    otherVenueEditable.value = event.value.otherVenue
    clubEditable.value = event.value.clubId
    otherOrganizerEditable.value = event.value.organizer
    directorEditable.value = event.value.director
    sponsorEditable.value = event.value.sponsor
    descEditable.value = event.value.description
    plannedRacesEditable.value = event.value.plannedRaces

    editMode.value = EditMode.EditDetails
}

function saveValues () {
    let e = { id: event.value.id }

    if ( ctlEditable.value != event.value.ctlId )
        e.ctlId = ctlEditable.value

    if ( directorEditable.value != event.value.director )
        e.director = directorEditable.value

    if ( sponsorEditable.value != event.value.sponsor )
        e.sponsor = sponsorEditable.value

    if ( titleEditable.value != event.value.title )
        e.title = titleEditable.value

    if ( descEditable.value != event.value.description )
        e.description = descEditable.value

    if ( venueEditable.value != event.value.venueId )
        e.venueId = venueEditable.value

    if ( clubEditable.value != event.value.clubId )
        e.clubId = clubEditable.value

    if ( otherVenueEditable.value != event.value.otherVenue )
        e.otherVenue = otherVenueEditable.value

    if ( otherOrganizerEditable.value != event.value.organizer )
        e.organizer = otherOrganizerEditable.value

    if ( plannedRacesEditable.value != event.value.plannedRaces )
        e.plannedRaces = plannedRacesEditable.value

    if ( new Date( eventFromEditable.value ).getTime() !== new Date( event.value.from ).getTime() )
        e.from = new Date( eventFromEditable.value )

    if ( eventToEditable.value == null || eventToEditable.value == '' || event.value.to == null || new Date( eventToEditable.value ).getTime() !== new Date( event.value.to ).getTime() )
        e.to = ( eventToEditable.value != null && eventToEditable.value != '' ) ? new Date( eventToEditable.value ) : null

    useRepo( EventsRepo ).save( e )

    editMode.value = EditMode.Display
}

function venueFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            venuesFilteredList.value = venuesRepo.value.venuesList( true )
        } )
        return
    }

    update( () => {
        const needle = val.toSearchable()
        venuesFilteredList.value = venuesRepo.value.venuesList( true ).filter( venue => venue.label.toSearchable().indexOf( needle ) > -1 )
    } )
}

function clubFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            clubsFilteredList.value = clubsRepo.value.clubsList( true )
        } )
        return
    }

    update( () => {
        const needle = val.toSearchable()
        clubsFilteredList.value = clubsRepo.value.clubsList( true ).filter( club => club.label.toSearchable().indexOf( needle ) > -1 )
    } )
}

function boatClassesFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            boatClassFilteredList.value = classesRepo.value.classesList( event.value )
        } )
        return
    }

    update( () => {
        const needle = val.toSearchable()
        boatClassFilteredList.value = classesRepo.value.classesList( event.value ).filter( boatClass => boatClass.label.toSearchable().indexOf( needle ) > -1 || boatClass.shortcut.toSearchable().indexOf( needle ) > -1 || String( boatClass.code ) == needle )
    } )
}

onMounted( () => {
    if ( !event.value.ignoreUpdateMembers && !membersRepo.value.hasMembers() ) {
        quasar.dialog( {
                component: Confirmation,
                componentProps: {
                    icon: 'refresh',
                    question: ts( 'wantSyncMembers' ),
                    yes: ts( 'loginAndUpdate' ),
                    no: ts( 'notNeeded' )
                }
            } )
            .onOk( () => {
                emits( 'logInAndUpdate' )

                event.value.ignoreUpdateMembers = true
                useRepo( EventsRepo ).save( event.value )
            } )
            .onCancel( () => {
                event.value.ignoreUpdateMembers = true
                useRepo( EventsRepo ).save( event.value )
            } )
    }
} )
</script>

<style lang="sass" scoped>
.group-separated-option
  border-bottom: solid 1px darkgray

.issue
  color: $negative
  font-weight: bold

.main-referee, .main-lifeguard
  font-weight: bold

.col-phone, .col-email
  max-width: 20%
</style>
