import Club from "@/orm/models/Club"
import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { ts } from "@/plugins/i18n-formatted"
import { AxiosRepository } from "@pinia-orm/axios"
import { useRepo } from 'pinia-orm'

export default class ClubsRepo extends AxiosRepository<Club> {
    use = Club

    clubsList ( addOther = false ) {
        return [
            ...( this.where( 'id', ( val ) => {
                    return val < 8000
                } ).get()
                    .map( ( club ) => ( { label: club.id + ' ' + club.name, value: club.id } ) )
                    .sort( ( a, b ) => a.label.localeCompare( b.label ) )
            ),
            ...( addOther ? [ { label: ts( 'chooseOtherOrganizer' ), value: null } ] : [] )
        ]
    }

    foreignClubs () {
        return this.where( 'id', ( val ) => {
            return val >= 9001 && val < 9999
        } ).get()
            .map( ( club ) => ( { label: club.id + " " + club.name, value: club.id } ) )
            .sort( ( a, b ) => a.label.localeCompare( b.label ) )
    }

    update () {
        const authToken = useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken )

        return this.api().get( '/catalogs/clubs', {
            headers: { Authorization: authToken.length > 0 ? "Bearer " + authToken : "" },
            dataTransformer: ( { data } ) => {
                return data.map( ( line ) => ( {
                    id: parseInt( line.id ),
                    name: String( line.name ),
                    shortcut: Object.hasOwn( line, 'shortcut' ) ? String( line.shortcut ) : undefined,
                    street: Object.hasOwn( line, 'street' ) ? String( line.street ) : undefined,
                    city: Object.hasOwn( line, 'city' ) ? String( line.city ) : undefined,
                    zip: Object.hasOwn( line, 'zip' ) ? String( line.zip ) : undefined,
                    manager: Object.hasOwn( line, 'manager' ) ? String( line.manager ) : undefined,
                    email: Object.hasOwn( line, 'email' ) ? String( line.email ) : undefined
                } ) )
            }
        } )
    }
}