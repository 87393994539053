import { Model } from 'pinia-orm'
import { Num, Str } from 'pinia-orm/decorators'

export default class RefereeRole extends Model {
    static entity = 'referee-roles'

    static readonly MainReferee = 1

    @Num( 0, { notNullable: true } ) declare id: number
    @Str( '', { notNullable: true } ) declare role: string
    @Str( null ) declare shortcut: string
    @Num( 0 ) declare points: number

    get order () {
        return this.isMainReferee ? 100 : 1
    }

    get isMainReferee () {
        return this.id === RefereeRole.MainReferee
    }

    static piniaOptions = {
        persist: true
    }
}
