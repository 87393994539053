<template>
    <q-dialog ref="dialogRef" @hide="onDialogHide">
        <q-card class="q-dialog-plugin">
            <q-card-section class="row items-center">
                <q-markup-table flat>
                    <tr>
                        <td>{{ ts( 'regId' ) }}</td>
                        <td>{{ props.crew.regId }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'fullName' ) }}</td>
                        <td>{{ props.crew.fullName }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'sex' ) }}</td>
                        <td>{{ ts( props.crew.sex ) }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'birthDate' ) }}</td>
                        <td>{{ ds( props.crew.birthDate, DateFormat.Short ) }}</td>
                    </tr>
                    <tr v-if="props.crew.isOneTime">
                        <td>{{ ts( 'citizenship' ) }}</td>
                        <td>{{ props.crew.nonMemberCitizenshipCountry.name }}</td>
                    </tr>
                    <tr v-if="props.crew.isOneTime && props.crew.nonMemberCitizenship === Country.CZ">
                        <td>{{ ts( 'birthCode' ) }}</td>
                        <td>{{ props.crew.nonMemberBirthCode }}</td>
                    </tr>
                    <tr v-if="props.crew.isOneTime && props.crew.nonMemberCitizenship !== Country.CZ">
                        <td>{{ ts( 'addressInCountry' ) }}</td>
                        <td>{{ props.crew.nonMemberAddress }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'club' ) }}</td>
                        <td>{{ props.crew.visibleClub }}</td>
                    </tr>
                    <tr>
                        <td>{{ ts( 'phone' ) }}</td>
                        <td v-html="props.crew.phone.formatPhone()"/>
                    </tr>
                    <tr>
                        <td>{{ ts( 'email' ) }}</td>
                        <td v-html="props.crew.email.formatEmail()"/>
                    </tr>
                </q-markup-table>
            </q-card-section>

            <q-card-actions align="right">
                <q-btn flat :label="props.close" color="primary" @click="onDialogOK" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script setup lang="ts">
import Country from "@model/Country"
import EventCrew from "@model/EventCrew"
import { useDialogPluginComponent } from "quasar"
import { DateFormat, ds, ts } from "@/plugins/i18n-formatted"

interface Props {
    close?: string
    color?: string
    crew: EventCrew
}

const props = withDefaults( defineProps<Props>(), {
    icon: 'info',
    color: 'primary',
    close: () => ts( 'close' )
} )

defineEmits( [ ...useDialogPluginComponent.emits ] )

const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent()
</script>