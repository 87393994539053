import { ts } from "@/plugins/i18n-formatted"

const missedResultsDef = [ 'DNC', 'DNS', 'DNF', 'TLE' ]

const otherResultsDef = {
    'DNC': 'boats + 1',
    'DNS': 'boats + 1',
    'OCS': 'boats + 1',
    'ZFP': 'Math.min( order + Math.ceil( 0.2 * ( boats + 1 ) * 10 ) / 10, boats + 1 )',
    'UFD': 'boats + 1',
    'BFD': 'boats + 1',
    'SCP': 'Math.min( order + Math.ceil( attr[percent|20] * ( boats + 1 ) * 10 ) / 10, boats + 1 )',
    'NSC': 'boats + 1',
    'DNF': 'boats + 1',
    'RET': 'boats + 1',
    'DSQ': 'boats + 1',
    'DNE': 'boats + 1',
    'RDG': 'Math.max( 1, Math.min( attr[points], boats + 1 ) )',
    'DPI': 'Math.min( order + attr[points], boats + 1 )',
    'STP': 'Math.min( order + attr[points], boats + 1 )',
    'TLE': 'boats + 1',
    'T1B': 'Math.min( order + Math.ceil( 0.3 * ( boats + 1 ) * 10 ) / 10, boats + 1 )'
}

/**
 * pozn.
 * dale potrebujeme umet ulozit puvodni dojezd pro kody, ktere pracuji s order (cili pri vlozeni se prida aktualni dojezd, pri zmene se schova predchozi dojezd)
 * a potrebujeme umet vlozit hodnoty pro attr podle definice v hranatych zavorkach (body/procenta, implicitni hodnota)
 *
 * A5.3 aktualne neresime
 * A6.1 Jestliže je loď diskvalifikována z rozjížďky nebo vzdá po dokončení, každá loď s horším umístěním v cíli se musí posunout o jedno místo vpřed.
 * A6.2 Jestliže protestní komise rozhodne o přiznání nápravy úpravou bodování lodě, bodová hodnocení dalších lodí se nemění, pokud protestní komise nerozhodne jinak.
 * 44.3c Bodový výsledek rozjížďky lodě, která přijala bodový trest, musí být počet bodů, které by loď dostala bez trestu, zhoršený o počet míst
 *       stanovený vypsáním závodu nebo plachetními směrnicemi. Když není stanoven počet míst, trest musí být 20 % z bodového hodnocení pro
 *       nedokončila, zaokrouhleno na nejbližší celé číslo (0,5 se zaokrouhluje nahoru). Výsledek dalších lodí nesmí být měněn; proto mohou mít dvě
 *       lodě stejný bodový výsledek. Avšak loď nesmí být hodnocena hůře, než kdyby nedokončila.
 */

export const implicitResult = 'DNC'

export const cannotBeDiscarded = [ 'DNE' ]

export function otherResults () {
    return Object.keys( otherResultsDef ).map( key => ( { value: key, label: ts( 'otherResult-' + key ), short: ts( 'otherResultShort-' + key ) } ) )
        .sort( ( a, b ) => ( a.value.localeCompare( b.value ) ) )
}

export function otherResultFormula ( result: string ): string | null {
    return Object.hasOwn( otherResultsDef, result ) ? otherResultsDef[ result ] : null
}

export function missedResults () {
    return missedResultsDef.map( key => ( { value: key, label: ts( 'otherResult-' + key ) } ) )
        .sort( ( a, b ) => ( a.value.localeCompare( b.value ) ) )
}
