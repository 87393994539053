import { date } from "quasar"
import { localization } from "@/plugins/i18n"

export function ts ( msg: string, args?: unknown ): string {
    // We want to intercept non-breaking space after one-letter prepositions in Czech language
    return localization.global.t( msg, args ).replaceAll( new RegExp( '($|;| |\\(|\\n)([aiousvkz]) ', 'ig' ), '$1$2\xa0' )
}

export enum DateFormat {
    EventFrom,
    EventTo,
    Short,
    DateTimeLong,
    DateTimeCommon,
    System,
    SystemDate
}

interface DateFormatStrings {
    [ DateFormat ]: string
}

const dateFormatMasks: { [ key: string ]: DateFormatStrings } = {
    cs: {
        [ DateFormat.EventFrom ]: 'D. M.',
        [ DateFormat.EventTo ]: 'D. M. YYYY',
        [ DateFormat.Short ]: 'DD. MM. YYYY',
        [ DateFormat.DateTimeLong ]: 'DD. MM. YYYY HH:mm:ss',
        [ DateFormat.DateTimeCommon ]: 'DD. M. YYYY H:mm',
        [ DateFormat.System ]: 'YYYY-MM-DD[T]HH:mm:ss[Z]Z',
        [ DateFormat.SystemDate ]: 'YYYY-MM-DD'
    },
    en: {
        [ DateFormat.EventFrom ]: 'MM/DD',
        [ DateFormat.EventTo ]: 'MM/DD/YYYY',
        [ DateFormat.Short ]: 'MM/DD/YYYY',
        [ DateFormat.DateTimeLong ]: 'MM/DD/YYYY HH:mm:ss',
        [ DateFormat.DateTimeCommon ]: 'MM/DD/YYYY HH:mm',
        [ DateFormat.System ]: 'YYYY-MM-DD[T]HH:mm:ss[Z]Z',
        [ DateFormat.SystemDate ]: 'YYYY-MM-DD'
    }
}

export function ds ( d: Date, format: DateFormat ): string {
    return date.formatDate( new Date( d ), dateFormatMasks[ localization.global.locale.value ][ format ] )
}

export function tcs ( msg: string, num: number ): string {
    return localization.global.t( msg, { n: num } ).replaceAll( / /g, '\xa0' )
}

export function ns ( n: number, format: string = undefined ): string {
    return localization.global.n( n, format )
}
