import { cannotBeDiscarded } from "@/orm/catalogs/OtherResults"
import BoatClass from "@model/BoatClass"
import Event from "@model/Event"
import EventClass from "@model/EventClass"
import EventCrew from "@model/EventCrew"
import { Model, useRepo } from 'pinia-orm'
import { BelongsTo, HasMany, Num, OnDelete, Str, Uid } from 'pinia-orm/decorators'

export default class EventBoat extends Model {
    static entity = 'events-boats'

    @Uid() declare id: string
    @Uid() declare eventId: string
    @BelongsTo( () => Event, 'eventId' ) declare event: Event
    @Num( 0, { notNullable: true } ) declare boatClassId: number
    @BelongsTo( () => BoatClass, 'boatClassId' ) declare boatClass: BoatClass
    @Str( '', { notNullable: true } ) declare country: string
    @Str( '', { notNullable: true } ) declare sailNumber: string
    @Str( null ) declare name: string | null
    @Str( null ) declare detail: string | null
    @HasMany( () => EventCrew, 'eventBoatId' ) @OnDelete( 'cascade' ) declare crew: EventCrew[]

    get dump () {
        return {
            id: this.id,
            boatClassId: this.boatClassId,
            country: this.country,
            sailNumber: this.sailNumber,
            name: this.name,
            detail: this.detail,
            crew: this.crew.map( ( crew ) => crew.dump )
        }
    }

    get isValid () {
        return this.crew !== undefined && this.crew !== null && this.crew.length > 0 && this.crew.filter( crew => !crew.isValid ).length === 0
    }

    get sailNo () {
        return this.country + ' ' + this.sailNumber
    }

    get orderedCrewList () {
        return this.crew.sort( ( a, b ) => ( ( a.captain && !b.captain ) ? -1 : ( ( !a.captain && b.captain ) ? 1 : a.order - b.order ) ) )
    }

    get eventClass () {
        return ( this.event.classes.filter( ( item ) => ( item.boatClassId === this.boatClassId ) ) )[ 0 ]
    }

    get points (): number {
        const eventClass = useRepo( EventClass ).withAllRecursive().where( 'eventId', this.event.id ).where( 'boatClassId', this.boatClassId ).first()

        if ( eventClass === null )
            return 0

        const defaultPoints = eventClass.totalBoats + 1

        return this.event.availableRaces( this.boatClassId )
            .reduce( ( sum, race ) => sum + ( this.isDiscardedRace( race.race ) ? 0 : race.boatPoints( this.id, defaultPoints ) ), 0 )
    }

    get results () {
        const eventClass = useRepo( EventClass ).withAllRecursive().where( 'eventId', this.event.id ).where( 'boatClassId', this.boatClassId ).first()

        if ( eventClass === null )
            return []

        const defaultPoints = eventClass.totalBoats + 1

        return this.event.availableRaces( this.boatClassId ).map( race=> ( { race: race.race, points: race.boatPoints( this.id, defaultPoints ), result: race.boatResult( this.id ), decided: race.boatDecidedResult( this.id ) } ) )
    }

    discardedRaces ( discarded = null ) {
        const eventClass = useRepo( EventClass ).withAllRecursive().where( 'eventId', this.event.id ).where( 'boatClassId', this.boatClassId ).first()

        if ( eventClass === null )
            return []

        const usedDiscarded = discarded === null ? eventClass.discarded : discarded

        const defaultPoints = eventClass.totalBoats + 1

        return this.event.availableRaces( this.boatClassId ).map( race => ( { race: race.race, points: race.boatPoints( this.id, defaultPoints ), discardable: cannotBeDiscarded.indexOf( race.boatResult( this.id ) ) === -1 } ) )
            .filter( race => race.discardable )
            .sort( ( a, b ) => ( a.points === b.points ? a.race - b.race : b.points - a.points ) )
            .slice( 0, usedDiscarded ).map( race => race.race )
    }

    isDiscardedRace ( race ) {
        return this.discardedRaces().indexOf( race ) >= 0
    }

    get captain () {
        if ( this.crew === null || this.crew === undefined || this.crew.length === 0 )
            return null

        const captains = this.crew.filter( crew => crew.captain )

        if ( captains.length === 0 )
            return null

        return captains[ 0 ]
    }

    static piniaOptions = {
        persist: true
    }
}
