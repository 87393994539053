import RefereeTitle from "@/orm/models/RefereeTitle"
import RefereesRepo from "@/orm/repositories/RefereesRepo"
import { Model, useRepo } from 'pinia-orm'
import { Attr, Str, HasManyBy } from 'pinia-orm/decorators'

export default class Referee extends Model {
    static entity = 'referees'

    @Str( '', { notNullable: true } ) declare id: string
    @Str( '', { notNullable: true } ) declare firstName: string
    @Str( '', { notNullable: true } ) declare lastName: string
    @Str( '', { notNullable: true } ) declare email: string
    @Str( '', { notNullable: true } ) declare phone: string
    @Str( null ) declare regId: string
    @Attr( [] ) declare refTitleIds: number[]
    @HasManyBy( () => RefereeTitle, 'refTitleIds' ) declare titles: RefereeTitle[]

    get fullName () {
        return this.firstName + ' ' + this.lastName
    }

    get reverseFullName () {
        return this.lastName + ' ' + this.firstName
    }

    get fullNameWithIdentification () {
        return 'R' + this.id + ' ' + this.firstName + ' ' + this.lastName + ' (' + this.identification + ')'
    }

    get reverseFullNameWithIdentification () {
        return 'R' + this.id + ' ' + this.lastName + ' ' + this.firstName + ' (' + this.identification + ')'
    }

    get reverseFullNameWithId () {
        return 'R' + this.id + ' ' + this.lastName + ' ' + this.firstName
    }

    get identification () {
        const me = useRepo( RefereesRepo ).withAllRecursive().find( this.id )

        if ( me !== null && me.titles !== undefined )
            return me.titles.map( ( title ) => ( title.visibleShortcut ) ).join( ', ' )
        else
            return ''
    }

    static piniaOptions = {
        persist: true
    }
}
