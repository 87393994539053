import { Model } from 'pinia-orm'
import { Str, Num } from 'pinia-orm/decorators'

export default class Country extends Model {
    static entity = 'countries'

    static CZ = 203

    static CZ_SAILCODE = "CZE"

    @Num( 0, { notNullable: true } ) declare id: number
    @Str( '', { notNullable: true } ) declare iso3: string
    @Str( '', { notNullable: true } ) declare name: string
    @Str( null ) declare sailCode: string

    static piniaOptions = {
        persist: true
    }
}