import BoatClass from "@model/BoatClass"
import EventRace from "@model/EventRace"
import { Model } from "pinia-orm"
import { BelongsTo, Num, Uid } from "pinia-orm/decorators"

export default class EventRaceSkippedClass extends Model {
    static entity = 'events-races-skipped-classes'

    @Uid() declare id: string
    @Uid() declare eventRaceId: string
    @BelongsTo( () => EventRace, 'eventRaceId' ) declare race: EventRace
    @Num( 0, { notNullable: true } ) declare boatClassId: number
    @BelongsTo( () => BoatClass, 'boatClassId' ) declare boatClass: BoatClass

    get dump () {
        return {
            boatClassId: this.boatClassId
        }
    }

    static piniaOptions = {
        persist: true
    }
}