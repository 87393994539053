import { Model } from 'pinia-orm'
import { Str } from 'pinia-orm/decorators'

export default class Lifeguard extends Model {
    static entity = 'lifeguards'

    @Str( '', { notNullable: true } ) declare id: string
    @Str( '', { notNullable: true } ) declare firstName: string
    @Str( '', { notNullable: true } ) declare lastName: string
    @Str( '', { notNullable: true } ) declare email: string
    @Str( '', { notNullable: true } ) declare phone: string
    @Str( null ) declare regId: string

    get fullName () {
        return this.firstName + ' ' + this.lastName
    }

    get reverseFullName () {
        return this.lastName + ' ' + this.firstName
    }

    get fullNameWithIdentification () {
        return 'L' + this.id + ' ' + this.firstName + ' ' + this.lastName
    }

    get reverseFullNameWithIdentification () {
        return 'L' + this.id + ' ' + this.lastName + ' ' + this.firstName
    }

    static piniaOptions = {
        persist: true
    }
}
