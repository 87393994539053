import Country from "@/orm/models/Country"
import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { AxiosRepository } from "@pinia-orm/axios"
import { useRepo } from "pinia-orm"

export default class CountriesRepo extends AxiosRepository<Country> {
    use = Country

    countryForSailList () {
        return this.whereNotNull( 'sailCode' ).get()
            .map( ( country ) => ( { label: country.sailCode + ' ' + country.name, value: country.sailCode } ) )
            .sort( ( a, b ) => ( a.value === Country.CZ_SAILCODE && b.value !== Country.CZ_SAILCODE ) ? -1 : ( ( a.value !== Country.CZ_SAILCODE && b.value === Country.CZ_SAILCODE ) ? 1 : a.label.localeCompare( b.label ) ) )
    }

    countryList () {
        return this.all()
            .map( ( country ) => ( { label: country.name, value: country.id } ) )
            .sort( ( a, b ) => ( a.value === Country.CZ && b.value !== Country.CZ ) ? -1 : ( ( a.value !== Country.CZ && b.value === Country.CZ ) ? 1 : a.label.localeCompare( b.label ) ) )
    }

    update () {
        const authToken = useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken )

        return this.api().get( '/catalogs/countries', {
            headers: { Authorization: authToken.length > 0 ? "Bearer " + authToken : "" },
            dataTransformer: ( { data } ) => {
                return data.map( ( line ) => ( {
                    id: parseInt( line.id ),
                    name: String( line.name.cs ),
                    iso3: String( line.iso3 ),
                    sailCode: Object.hasOwn( line, 'sailCode' ) ? String( line.sailCode ) : undefined
                } ) )
            }
        } )
    }
}