import { Model } from 'pinia-orm'
import { Num, Str } from 'pinia-orm/decorators'

export default class Club extends Model {
    static entity = 'clubs'

    @Num( 0, { notNullable: true } ) declare id: number
    @Str( '', { notNullable: true } ) declare name: string
    @Str( null ) declare shortcut: string | null
    @Str( null ) declare street: string | null
    @Str( null ) declare city: string | null
    @Str( null ) declare zip: string | null
    @Str( null ) declare manager: string | null
    @Str( null ) declare email: string | null

    static piniaOptions = {
        persist: true
    }

    get fullName () {
        return this.id + ' ' + this.name
    }
}