import { tcs, ts } from "@/plugins/i18n-formatted"

Date.prototype.toSimpleDate = function () {
    return this.toISOString().replace( /T.*$/, '' )
}

Date.prototype.isSameAs = function ( second ) {
    return this.toSimpleDate() == second.toSimpleDate()
}

Date.prototype.verb = function () {
    const d = new Date( this )

    const today = Date.now()

    const duration = Math.floor( ( today.valueOf() - d.valueOf() ) / 1000 )

    if ( duration < 5 * 60 )
        return ts( 'now' )

    if ( duration < 60 * 60 )
        return tcs( 'beforeMinutes', Math.floor( duration / 60 ) )

    if ( duration < 6 * 24 * 60 * 60 )
        return tcs( 'beforeHours', Math.floor( duration / 3600 ) )

    if ( duration < 24 * 60 * 60 )
        return ts( 'today' )

    if ( duration < 2 * 24 * 60 * 60 )
        return ts( 'yesterday' )

    if ( duration < 7 * 24 * 60 * 60 )
        return tcs( 'beforeDays', Math.floor( duration / 86400 ) )

    if ( duration < 8 * 24 * 60 * 60 )
        return ts( 'weekAgo' )

    if ( duration < 30 * 24 * 60 * 60 )
        return ts( 'daysAgo', Math.floor( duration / 86400 ) )

    if ( duration < 365 * 24 * 60 * 60 )
        return ts( 'beforeMonths', Math.floor( duration / 30 / 86400 ) )

    return ts( 'yearAndMoreAgo' )
}