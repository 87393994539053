// Polyfills for everything what's necessary to work with TypeScript/modern frameworks
import 'core-js'

// My type definitions and enhancements
import './utils/string'
import './utils/date'
import './utils/array'

// Sentry
import * as Sentry from "@sentry/vue"

// Vue application and localization
import { createApp } from 'vue'
import { i18n } from './plugins/i18n'
import { router } from './plugins/router'
import { viewlayer } from './plugins/viewlayer'
import { datalayer } from './plugins/datalayer'
import App from './App.vue'

// Application layer initialization
const app = createApp( App )

Sentry.init( {
    app,
    dsn: "https://393684fdccac05f6303b826f2acbd231@o4506809198706688.ingest.sentry.io/4506809200672768",
    release: import.meta.env.DEV ? 'development' : 'production',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration( {
            maskAllText: false,
            blockAllMedia: false
        } )
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [ "localhost", /^https:\/\/api\.sailing\.cz/ ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
} )

app.use( i18n )
app.use( datalayer )
app.use( viewlayer )
app.use( router )

// And start application
app.mount( '#app' )
