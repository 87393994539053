import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { ts } from "@/plugins/i18n-formatted"
import { AxiosRepository } from "@pinia-orm/axios"
import Venue from "@/orm/models/Venue"
import { useRepo } from "pinia-orm"

export default class VenuesRepo extends AxiosRepository<Venue> {
    use = Venue

    venuesList ( addOther = false ) {
        return [
            ...( this.all()
                    .map( ( venue ) => ( { label: venue.localizedName, value: venue.id } ) )
                    .sort( ( a, b ) => a.label.localeCompare( b.label ) )
            ),
            ...( addOther ? [ { label: ts( 'chooseOtherVenue' ), value: null } ] : [] )
        ]
    }

    venueIdByName ( name ) {
        return this.where( 'name', name ).orWhere( 'nameEn', name ).first()?.id
    }

    update () {
        const authToken = useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken )

        return this.api().get( '/catalogs/venues', {
            headers: { Authorization: authToken.length > 0 ? "Bearer " + authToken : "" },
            dataTransformer: ( { data } ) => {
                return data.map( ( line ) => ( {
                    id: parseInt( line.id ),
                    name: String( line.name.cs ),
                    nameEn: Object.hasOwn( line.name, 'en' ) ? String( line.name.en ) : undefined,
                    country: Object.hasOwn( line, 'country' ) ? String( line.country ) : undefined,
                    maxCircuits: Object.hasOwn( line, 'maxCircuits' ) ? parseInt( line.maxCircuits ) : undefined,
                    geoLat: Object.hasOwn( line, 'geo' ) && Object.hasOwn( line.geo, 'lat' ) ? String( line.geo.lat ) : undefined,
                    geoLng: Object.hasOwn( line, 'geo' ) && Object.hasOwn( line.geo, 'lng' ) ? String( line.geo.lng ) : undefined
                } ) )
            }
        } )
    }
}