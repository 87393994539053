import { otherResultFormula } from "@/orm/catalogs/OtherResults"
import { tcs } from "@/plugins/i18n-formatted"
import nvl from "@/utils/nvl"
import EventBoat from "@model/EventBoat"
import EventRace from "@model/EventRace"
import Sandbox from "@nyariv/sandboxjs"
import { Model } from 'pinia-orm'
import { BelongsTo, Num, Str, Uid } from 'pinia-orm/decorators'

export default class EventResult extends Model {
    static entity = 'events-results'

    @Uid() declare id: string
    @Uid() declare eventRaceId: string
    @BelongsTo( () => EventRace, 'eventRaceId' ) declare race: EventRace
    @Uid() declare eventBoatId: string
    @BelongsTo( () => EventBoat, 'eventBoatId' ) declare boat: EventBoat
    @Num( null ) declare order: number | null
    @Str( null ) declare other: string | null
    @Num( null ) declare attr: number | null

    get dump () {
        return {
            eventBoatId: this.eventBoatId,
            _boat: { country: this.boat?.country, sailNumber: this.boat?.sailNumber },
            order: this.order,
            other: this.other,
            attr: this.attr,
            _points: this.points
        }
    }

    get points (): number {
        if ( this.order !== null && this.other === null )
            return this.order

        const formula = otherResultFormula( this.other )

        const boats = this.race.totalBoats( this.boat?.boatClassId )
        const order = nvl( this.order, boats )
        const attr = nvl( this.attr, boats )

        const sandbox = new Sandbox()
        const exec = sandbox.compile( 'return ' + formula.replace( /attr\[.*?\]/, 'attr' ) )

        const result = exec( {
            boats: boats,
            order: order,
            attr: attr
        } ).run()

        return result
    }

    get result () {
        return nvl( this.other, this.order )
    }

    set result ( val ) {
        // ignore
    }

    orderTo ( comparable ) {
        const orderA = this.order
        const orderB = comparable.order

        if ( orderA !== null && orderB !== null )
            return orderA - orderB

        if ( orderA !== null )
            return -1

        if ( orderB !== null )
            return 1

        if ( this.other === null || comparable.other === null )
            return 0

        return this.other > comparable.other ? 1 : ( this.other < comparable.other ? -1 : 0 )
    }

    get attrInfo () {
        if ( this.attr === null )
            return ''

        if ( this.other === null )
            return this.attr

        const formula = otherResultFormula( this.other )

        if ( formula === null )
            return this.attr

        const matches = formula.match( /attr\[\s*(percent|points).*?\]/ )

        if ( ! matches )
            return this.attr

        const attrType = matches[ 1 ]

        return attrType === 'points' ? tcs( 'pointsNo', this.attr ) : Math.round( this.attr * 100 ) + " %"
    }

    static piniaOptions = {
        persist: true
    }
}
