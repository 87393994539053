<template>
    <q-dialog ref="dialogRef">
        <q-card class="q-dialog-plugin">
            <q-card-section class="row items-center">
                {{ props.title }}
            </q-card-section>

            <q-card-section class="row items-center">
                <qe-form class="dialog-form">
                    <qe-input v-model="firstName" :label="ts( 'firstName' )" autofocus />
                    <qe-input v-model="lastName" :label="ts( 'lastName' )" />
                    <qe-combo-box
                        v-model="country" :options="countryList" :label="ts( 'citizenship' )" autofocus
                        @filter="countryFilter" @keydown.enter="chooseOnlyOneCountry" @keydown.tab="chooseOnlyOneCountry" />
                    <qe-input v-model="birthCode" :label="ts( 'birthCode' )" v-if="country === Country.CZ" />
                    <qe-input v-model="birthDate" type="date" :label="ts( 'birthDate' )" v-else />
                    <qe-input v-model="address" :label="ts( 'addressInCountry' )" v-if="country !== Country.CZ" />
                    <qe-input v-model="email" type="email" :label="ts( 'email' )" />
                    <qe-input v-model="phone" :label="ts( 'phone' )" />
                    <div class="q-mt-sm" v-if="country !== Country.CZ">
                        {{ ts( 'sex' ) }}
                        <q-option-group
                            v-model="sex" :options="[ { value: 'male', label: ts( 'male' ) }, { value: 'female', label: ts( 'female' ) } ]" inline
                            @keydown.left="selectOtherSex" @keydown.right="selectOtherSex" />
                    </div>
                </qe-form>
            </q-card-section>

            <q-card-actions align="right">
                <q-btn flat :label="props.save" color="primary" @click="onSave" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script setup lang="ts">
import InfoBox from "@dialogs/InfoBox.vue"
import Country from "@model/Country"
import QeComboBox from "@qe/qeComboBox.vue"
import QeForm from "@qe/qeForm.vue"
import QeInput from "@qe/qeInput.vue"
import CountriesRepo from "@repo/CountriesRepo"
import { useRepo } from "pinia-orm"
import { QSelect, useDialogPluginComponent, useQuasar } from "quasar"
import { ts } from "@/plugins/i18n-formatted"
import { computed, ref } from "vue"
import { rodnecislo } from 'rodnecislo'

interface Props {
    title?: string
    save?: string
    color?: string
}

const props = withDefaults( defineProps<Props>(), {
    title: '',
    color: 'primary',
    save: () => ts( 'ok' )
} )

defineEmits( [ ...useDialogPluginComponent.emits ] )

const quasar = useQuasar()

const { dialogRef, onDialogOK } = useDialogPluginComponent()

const countriesRepo = computed( () => ( useRepo( CountriesRepo ) ) )

const firstName = ref( '' )
const lastName = ref( '' )
const birthDate = ref( null )
const birthCode = ref( '' )
const email = ref( '' )
const phone = ref( '' )
const country = ref( Country.CZ )
const address = ref( '' )
const sex = ref( 'male' )

const countryList = ref( countriesRepo.value.countryList() )

function countryFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            countryList.value = countriesRepo.value.countryList()
        } )
        return
    }

    update(
        () => {
            const needle = val.toSearchable()
            countryList.value = countriesRepo.value.countryList().filter( country => country.label.toSearchable().indexOf( needle ) > -1 )
        },
        ( ref: QSelect ) => {
            if ( val !== "" && ref.options.length > 0 ) {
                ref.setOptionIndex( -1 )
                ref.moveOptionSelection( 1, true )
            }
        }
    )
}

function chooseOnlyOneCountry () {
    if ( countryList.value != null && countryList.value.length == 1 )
        country.value = countryList.value[ 0 ].value
}

function selectOtherSex () {
    sex.value = sex.value === 'male' ? 'female' : 'male'
}

function isValidBirthCode ( birthCode ) {
    const bc = rodnecislo( birthCode )

    return bc.isValid()
}

function onSave () {
    const currentYear = new Date().getFullYear()
    const oldestYear = currentYear - 115

    const birthYear = country.value === Country.CZ
        ? rodnecislo( birthCode.value.trim().replace( '/', '' ) ).year()
        : ( birthDate.value !== null ? new Date( birthDate.value )?.getFullYear() : null )

    if ( firstName.value === undefined || firstName.value.trim().length <= 1 || lastName.value === undefined || lastName.value.trim().length <= 1
        || ( country.value === Country.CZ && ( birthCode.value === undefined || !isValidBirthCode( birthCode.value.trim().replace( '/', '' ) ) ) )
        || ( country.value !== Country.CZ && ( address.value.trim().length <= 2 || birthYear === undefined || birthYear === null || birthYear <= oldestYear || birthYear >= currentYear ) ) ) {
        quasar.dialog( {
            component: InfoBox,
            componentProps: {
                message: ts( country.value === Country.CZ ? 'missingOneTimeCrewRegData' : 'missingOneTimeCrewRegDataForeign' )
            }
        } )

        return
    }

    onDialogOK( {
        firstName: firstName.value.trim(),
        lastName: lastName.value.trim(),
        birthCode: country.value === Country.CZ ? birthCode.value.trim().replace( '/', '' ) : null,
        email: email.value.trim(),
        phone: phone.value.trim(),
        address: address.value.trim(),
        citizenship: country.value,
        birthDate: ( country.value !== Country.CZ && birthDate.value !== null ) ? new Date( birthDate.value ) : null,
        sex: sex.value
    } )
}
</script>

<style scoped lang="sass">
.dialog-form
  width: 100%
</style>